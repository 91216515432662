
import { useEffect, useState, useRef } from "react";

import { generatePDF } from "./Pdf";
import { generateDoc } from "./Doc";


export default function Export_Data({ response}) {
    const [isOpen, setIsOpen] = useState(false);
  //   const questions = [
  //     {
  //         question: "What is the age requirement for the water polo club that Tyrone is interested in?",
  //         options: ["Under 14", "Under 16", "Under 18"],
  //         answer: "Under 16",
  //     },
  //     {
  //         question: "On which days of the week does the under-16s water polo club train?",
  //         options: ["Mondays and Wednesdays", "Tuesdays and Thursdays", "Fridays and Sundays"],
  //         answer: "Tuesdays and Thursdays",
  //     },
  //     {
  //         question: "What time does the under-16s water polo club training session start on Tuesdays and Thursdays?",
  //         options: ["5:30 PM", "6:30 PM", "7:30 PM"],
  //         answer: "6:30 PM",
  //     },
  //     {
  //         question: "When does the under-16s water polo club training begin?",
  //         options: ["September 1st", "September 2nd", "September 3rd"],
  //         answer: "September 2nd",
  //     },
  //     {
  //         question: "What is the fee for under-18s to join the water polo club?",
  //         options: ["There is a fee", "It is free", "It depends on the age"],
  //         answer: "It is free",
  //     },
  //     {
  //       question: "What time does the under-16s water polo club training session start on Tuesdays and Thursdays?",
  //       options: ["5:30 PM", "6:30 PM", "7:30 PM"],
  //       answer: "6:30 PM",
  //   },
  //   {
  //       question: "When does the under-16s water polo club training begin?",
  //       options: ["September 1st", "September 2nd", "September 3rd"],
  //       answer: "September 2nd",
  //   },
  //   {
  //       question: "What is the fee for under-18s to join the water polo club?",
  //       options: ["There is a fee", "It is free", "It depends on the age"],
  //       answer: "It is free",
  //   },{
  //     question: "What time does the under-16s water polo club training session start on Tuesdays and Thursdays?",
  //     options: ["5:30 PM", "6:30 PM", "7:30 PM"],
  //     answer: "6:30 PM",
  // },
  // {
  //     question: "When does the under-16s water polo club training begin?",
  //     options: ["September 1st", "September 2nd", "September 3rd"],
  //     answer: "September 2nd",
  // },
  // {
  //     question: "What is the fee for under-18s to join the water polo club?",
  //     options: ["There is a fee", "It is free", "It depends on the age"],
  //     answer: "It is free",
  // },
  // ];
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };

      const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

    return (
        <>
        <div ref={dropdownRef} className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="inline-flex items-center bg-[#6247AA] px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  >
                    Export
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 14.293a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L10 11.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {isOpen && (
                    <div className="origin-top-right absolute left-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                                <a
                                  
                                  onClick={() => generatePDF(response)}
                                  className="block px-4 py-2 text-sm cursor-pointer text-gray-700 hover:bg-gray-100"
                                >
                                  Export as PDF
                                </a>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => generateDoc(response)}
                                >
                                  Export as Doc
                                </a>
                              </div>
                        {/* <div className="relative">
                          <button
                            className="w-full flex text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              setActiveLevel2(!activeLevel2);
                              setActiveLevel1(false);
                              setActiveLevel3(false);
                            }}
                          >
                            Export Questions
                            <svg
                              className="absolute right-0 mr-3 h-5 w-5 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.293 14.293a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L10 11.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          {activeLevel2 && (
                            <div className="absolute left-full top-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                              <div className="py-1">
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  Export as PDF
                                </a>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  Export as Doc
                                </a>
                              </div>
                            </div>
                          )}
                        </div>

                                <div className="relative">
                                  <button
                                    className="w-full flex text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    onClick={() => {
                                      setActiveLevel3(!activeLevel3);
                                      setActiveLevel1(false);
                                      setActiveLevel2(false);
                                    }}
                                  >
                                    Export Answers
                                    <svg
                                      className="absolute right-0 mr-3 h-5 w-5 text-gray-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10.293 14.293a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L10 11.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                  {activeLevel3 && (
                                    <div className="absolute left-full top-0 mt-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                      <div className="py-1">
                                        <a
                                          href="#"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                          Export as PDF
                                        </a>
                                        <a
                                          href="#"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                          Export as Doc
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div> */}
                              </div>
                          )}
                        </div>
        </>
    )

}