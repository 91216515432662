import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { useState, useEffect } from "react";
import { auth, provider } from "../../backend/config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FacebookAuthProvider } from "firebase/auth";
import axios from "axios";
import { serverUrl } from "../../backend/config";

export default function Login({ setAuthToken }) {
  const [email, setEmail] = useState(""); // State variable for email
  const [password, setPassword] = useState(""); // State variable for password
  const [isEmailLoginLoading, setIsEmailLoginLoading] = useState(false); // Loading state for email login
  const [isLoading, setIsLoading] = useState(false); // Global loading state
  // const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard');
        setAuthToken(true);
      }
      else {
        setAuthToken(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  const gClick = async () => {
    try {
      setIsLoading(true);

      setPersistence(auth, browserLocalPersistence);

      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        try {
          await axios.post(`${serverUrl}/api/user`, {
            name: result.user.displayName,
            email: result.user.email,
            type: 'google',
            uid: result.user.uid
          });
          navigate('/dashboard');
        } catch (err) {
          console.log(err);
        }
      }
    } catch (error) {
      console.error("Error initiating Google Sign-In:", error);
      setIsLoading(false);
    }
  };

  const fbprovider = new FacebookAuthProvider();

  const fbClick = async () => {
    try {
      setIsLoading(true);
      setPersistence(auth, browserLocalPersistence);
      
      const result = await signInWithPopup(auth, fbprovider);
      if (result.user) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Error initiating Facebook Sign-In:", error);
    }
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      setIsEmailLoginLoading(true);
      setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User logged in:", user);
    } catch (error) {
      console.error("Error signing in with email/password:", error);
      Swal.fire("Error", "Invalid email or password.", "error");
      setIsEmailLoginLoading(false);
    }
  };



  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-screen flex justify-center items-center bg-dark-body bg-opacity-50">
          <div className="loader"></div>
        </div>
      )}
      <div className={`min-h-screen flex ${isLoading ? 'hidden' : ''}`}>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex items-center justify-start">
                <svg
                  viewBoxSize="575"
                  className="w-10 h-10 justify-center text-center fill-[#26A612]"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  viewBox="16.14999771118164 15.737491607666016 724.5 403.8750305175781"
                >
                  <path
                    d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"
                  ></path>
                </svg>
                <p className="text-4xl pl-5 font-extrabold font-[Libre-Baskerville] text-[#26A612]">Quizeon</p>
              </div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">Sign in to your account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '} Don't have an account yet?   {'  '}
                <a href="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                   Sign up
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Sign in with</p>

                  <div className="mt-1 grid grid-cols-2 gap-3">
                    <div>
                      <div
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        onClick={gClick}
                      >
                        <span className="sr-only">Sign in with Google</span>
                        <FcGoogle className="text-4xl cursor-pointer" />
                      </div>
                    </div>
                    <div 
                    onClick={fbClick}
                    >
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Facebook</span>
                        <FaFacebook className="text-4xl text-blue-700 cursor-pointer" />
                      </a>
                    </div>
                    
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white dark:bg-dark-body text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                
                  <form onSubmit={handleEmailLogin} className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 dark:text-white">
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm">
                        <a
                          onClick={() => navigate('/forgot')}
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {isEmailLoginLoading ? "Signing in..." : "Sign in"}
                      </button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
