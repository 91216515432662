import { useState } from "react";
import { MdDelete } from "react-icons/md";
import Popup from "./Popup";
import { FiAlertTriangle } from "react-icons/fi";

export default function Short_ans({ short_ans, edit, setEdit }) {
  const [response, setResponse] = useState(short_ans);
  const [showModal, setShowModal] = useState(false);
  const update = { question: "", answer: "" ,options:null};


  const handleInputChange = (index, option, value) => {
    const updatedResponse = [...response];
    if (option === "qn") {
      updatedResponse[index].question = value;
    } else {
      updatedResponse[index].answer = value;
    }
    setResponse(updatedResponse);
  };

  if (!response || response.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <FiAlertTriangle className="h-20 w-20 text-red-500 mb-3" />
        <span className="text-red-500">{"An error occurred"}</span>
      </div>
    );
  }

  return (
    <>
      {/* Generate the question and answer container */}
      {response.length > 0 &&
        response.map((res, index) => (
          <div key={index} className="border rounded-md p-4 mb-5 w-full mx-auto max-w-2xl">
            <div className="flex justify-between items-center">
              <h2 className="text-xs lg:text-lg font-semibold text-black justify-start mb-5">
                Question {index + 1}
              </h2>
              <span className="text-xs flex lg:text-lg font-semibold justify-between gap-6 text-black mb-5">
                <MdDelete
                  size={22}
                  className="cursor-pointer hover:text-red-700"
                  onClick={() => {
                    const updatedResponse = response.filter((_, i) => i !== index);
                    setResponse(updatedResponse);
                  }}
                />
              </span>
            </div>
            {edit ? (
              <input
                type="text"
                className="text-lg lg:text-base font-semibold w-full bg-gray-100"
                value={res.question}
                onChange={(e) => handleInputChange(index, "qn", e.target.value)}
              />
            ) : (
              <h4 className="text-lg lg:text-base font-semibold">{res.question}</h4>
            )}
            <div className="mt-4">
              <span className="text-lg lg:text-base font-semibold">Answer: </span>
              {edit ? (
                <input
                  type="text"
                  className="text-lg lg:text-base font-normal bg-gray-100"
                  value={res.answer}
                  onChange={(e) => handleInputChange(index, "ans", e.target.value)}
                />
              ) : (
                <span className="text-lg lg:text-base font-normal">{res.answer}</span>
              )}
            </div>
          </div>
        ))}

      {showModal && <Popup res={update} showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
}
