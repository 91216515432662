
import pdfmake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfmake.vfs = pdfFonts.pdfMake.vfs; // Register fonts

const optionsAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const generatePDF = (quizData) => {
  const docDefinition = {
    background: function (currentPage, pageSize) {
      return [
        {
          canvas: [
            { type: "line", x1: 5, y1: 5, x2: 590, y2: 5, lineWidth: 1 }, //Up line
            { type: "line", x1: 5, y1: 5, x2: 5, y2: 835, lineWidth: 1 }, //Left line
            { type: "line", x1: 5, y1: 835, x2: 590, y2: 835, lineWidth: 1 }, //Bottom line
            { type: "line", x1: 590, y1: 5, x2: 590, y2: 835, lineWidth: 1 }, //Rigth line
          ],
        },
      ];
    },
    header: {
      text: "Generated on Quizeon",
      alignment: "center",
      fontSize: 10,
      margin: [0, 10, 0, 30],
    },
    content: [{ text: "Quiz", style: "header" }, { text: "\n" }],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
      question: {
        fontSize: 14,
        bold: true,
        margin: [0, 5, 0, 0],
      },
    },
    pageSize: "A4",
    watermark: { text: "Quizeon", opacity: 0.1, bold: true, italics: false },

    pageMargins: [50, 50, 50, 50], // Adjust margins as needed,

    footer: function (currentPage, pageCount) {
      return {
        text: `Generated on Quizeon ${new Date().toLocaleString()} | Page ${currentPage} of ${pageCount}`,
        alignment: "center",
        fontSize: 10,
        margin: [0, 30, 0, 10],
      };
    },
  };

  quizData.forEach((questionObj, index) => {
    docDefinition.content.push({
      text: `${index + 1}. ${questionObj.question}`,
      style: "question",
    });
    quizData[index].options.forEach((option, optionIndex) => {
      docDefinition.content.push({
        text: `${optionsAlphabet[optionIndex]}. ${option}`,
        margin: [15, 5, 0, 0],
      });
    });
    docDefinition.content.push({
      text: `Answer: ${questionObj.answer}`,
      margin: [0, 10, 0, 0],
    });
    docDefinition.content.push({ text: "\n" });
  });

  const pdfDocGenerator = pdfmake.createPdf(docDefinition);
  pdfDocGenerator.download("quiz-results.pdf");
};