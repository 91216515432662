// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBp96uizTK0x_XN96HBufFRCr9Zz20bjYo",
  authDomain: "adshopify-18569.firebaseapp.com",
  projectId: "adshopify-18569",
  storageBucket: "adshopify-18569.appspot.com",
  messagingSenderId: "535972333598",
  appId: "1:535972333598:web:e8db0965a7f20ef7dc060f",
  measurementId: "G-076WNBMBM3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const serverUrl = "https://adshopify-18569.uc.r.appspot.com/";
// export const serverUrl = "http://localhost:5000";

export default app;