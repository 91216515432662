import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { IoMenu, IoSunny, IoMoon } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { auth } from "../../backend/config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
} 

export default function Header() {
  const navigate = useNavigate();
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? storedTheme : "light";
  });
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);
  return (
    <div className="bg-transparent backdrop-blur-xl sticky top-0 z-50">
      <Disclosure as="nav" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {({ open }) => (
          <>
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <IoMdClose className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <IoMenu className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <svg
                    viewBox="575"
                    className="w-10 h-10 justify-center text-center fill-[#7a329d]"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                  >
                    <path d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"></path>
                  </svg>
                  <p className="text-2xl pl-5 font-extrabold font-[Libre-Baskerville] text-[#7a329d]">
                    Quizeon
                  </p>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8 ">
                  {/* Desktop navigation links */}
                  <a
                    href="#home"
                    className="text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 text-base font-medium border-indigo-500"
                  >
                    Home
                  </a>
                  <a
                    href="#features"
                    className="text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 text-base font-medium"
                  >
                    Features
                  </a>
                  <a
                    href="#pricing"
                    className="text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 text-base font-medium"
                  >
                    Pricing
                  </a>
                  <a
                    href="#contact"
                    className="text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1  text-base font-medium"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="flex items-center">
                {/* Theme toggle */}
                <div
                  className="transition duration-500 ease-in-out text-gray-800 font-bold py-2 px-4 rounded cursor-pointer"
                  onClick={toggleTheme}
                >
                  {theme === "light" ? (
                    <IoSunny className="w-6 h-6 hover:text-yellow-500 hover:rotate-180 hover:duration-500 duration-500" />
                  ) : (
                    <IoMoon className="w-6 h-6 text-white hover:text-black -rotate-90 hover:rotate-45 hover:duration-500 duration-500"/>
                  )}
                </div>
                {/* Authentication buttons */}
                {auth.currentUser ? (
                  <button
                    onClick={() => navigate("/dashboard")}
                    className="text-black dark:text-white font-bold py-2 px-4 rounded ml-4 md:ml-0"
                  >
                    Dashboard
                  </button>
                ) : (
                  <div className="flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 mr-8 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => navigate("/signup")}
                    >
                      Sign Up
                    </button>
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => navigate("/signin")}
                    >
                      Login
                    </button>
                  </div>
                )}
                {/* User profile menu */}
                {auth.currentUser && (
                  <div className="hidden md:ml-4 md:flex md:items-center">
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={
                              auth.currentUser.photoURL
                                ? auth.currentUser.photoURL
                                : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            }
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={() => {
                                  auth.signOut();
                                }}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
            </div>

            {/* Mobile menu panel */}
            <Disclosure.Panel className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">
                <a
                  href="#home"
                  className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  Home
                </a>
                <a
                  href="#features"
                  className="border-transparent text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  Features
                </a>
                <a
                  href="#pricing"
                  className="border-transparent text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  Pricing
                </a>
                <a
                  href="#contact"
                  className="border-transparent text-gray-500 dark:text-white hover:text-gray-700 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  Contact Us
                </a>
              </div>
              {auth.currentUser && (
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4 sm:px-6">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          auth.currentUser.photoURL
                            ? auth.currentUser.photoURL
                            : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        }
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {auth.currentUser.displayName}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    <a
                      href="/"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                      onClick={() => {
                        auth.signOut();
                      }}
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
