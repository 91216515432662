import React from 'react';
import { SHA256 } from 'crypto-js';

export default function Choose_Quiz(){
    const generateKey = () => {
        const timestamp = Date.now().toString();
        const hash = SHA256(timestamp).toString();
        return hash.slice(0, 6).toUpperCase(); // Return first 6 characters of the hash
      };

    const genType = [
        {
            name: "Content",
            icon: "📝",
            description: "Create a quiz based on content",
            url: `/generate-quiz/content`
        },
        {
            name: "Audio",
            icon: "🔊",
            description: "Create a quiz based on audio",
            url: `/generate-quiz/audio`
        }
    
        // More people...
      ]
     

    return (



        <div className=" flex items-center justify-center">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="py-12 lg:py-24">
      <ul role="list" className="space-y-4 sm:grid sm:grid-cols-2 sm:justify-center sm:place-items-center sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:justify-center lg:place-items-center lg:gap-8">
        {genType.map((type) => (
          <li key={type.name} className="text-center rounded-lg xl:px-10 xl:text-left ">
            <a href={type.link} className="block group hover:shadow-xl transition duration-300 ease-in-out ">
              <div className="bg-gray-300 space-y-6 xl:space-y-10 rounded-xl p-10 group-hover:cursor-pointer" onClick={() => window .location.href = type.url}>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-500 group-hover:bg-indigo-600 transition duration-300 ease-in-out ">
                  <span className="text-white">{type.icon}</span>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 text-center group-hover:text-indigo-600 transition duration-300 ease-in-out">{type.name}</h3>
                <p className="text-sm text-gray-500 group-hover:text-gray-600 transition duration-300 ease-in-out">{type.description}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
</div>

    )
  }
  
