import img1 from "./images/Saas_2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileExcel, faFileWord, faFileCsv, faFileImage } from '@fortawesome/free-solid-svg-icons';
const icons = [
  { icon: faFilePdf, label: 'PDF' },
  { icon: faFileExcel, label: 'Excel' },
  { icon: faFileWord, label: 'Word' },
  { icon: faFileCsv, label: 'CSV' },
  { icon: faFileImage, label: 'Image' }
];


export default function Feature() {
  return (
    <>
      <section className="relative overflow-hidden dark:text-white mt-10" id="#features" >
        <div className=" ">
          <div className="container">
            <div className="text-center">
              <span className="text-sm font-medium py-1 px-3 rounded-full text-primary bg-primary/10">
                Features
              </span>
              <h1 className="text-3xl/tight font-medium mt-3 mb-4">
              Quizeon's <span className="text-[#26A612]">Quest</span> , Knowledge behest!
              </h1>
              <p className="text-gray-500 dark:text-primary_content">
              Easily craft tailored questions with Quizeon. Generate any type of question seamlessly to align perfectly with your objectives or content niche!              </p>
            </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center mt-10">
                <div className="relative -ms-36 pl-10 -me-24">
                  <div className="hidden sm:block">
                    <div className="after:w-20 after:h-20 after:absolute after:-top-8 after:-start-8 2xl:after:end-0 after:bg-dot5"></div>
                    <div className="before:w-20 before:h-20 before:absolute before:-bottom-8 before:-end-8 before:bg-dot2"></div>
                  </div>
                  <img
                    src={img1}
                    alt="saas1"
                    data-aos="fade-right"
                    data-aos-duration="400"
                    className="w-[1024px] h-[500px] rounded-lg mx-auto"
                  />
                </div>

                <div className="lg:ms-36">
                  <div
                    data-fc-type="accordion"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    {/* <!-- 1 --> */}
                    <div
                      className="pt-2 inline-flex items-center gap-x-4 w-full font-medium text-left text-gray-800 transition-all hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                      data-fc-type="collapse"
                    >
                      <div className="bg-blue-500/10 rounded-lg flex items-center justify-center h-12 w-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-green-500"
                          shape-rendering="geometricPrecision"
                          text-rendering="geometricPrecision"
                          image-rendering="optimizeQuality"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          viewBox="0 0 414 512.01"
                        >
                          <path d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55C4.04 494.95 0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39C17.06 4.04 26.05 0 35.95 0zm233.33 2.23a13.2 13.2 0 012.49 2.17l136.55 132.31H269.28V2.23zM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98 0-5.51 4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99H91.31zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99H91.31z" />
                        </svg>
                      </div>
                      <h1 className="font-medium mb-4 mt-2">
                        Multi-Choice Questions
                      </h1>
                    </div>
                  
                    <div className="my-6"></div>

                    {/* <!-- 2 --> */}
                    <div
                      className="pt-2 inline-flex items-center gap-x-4 w-full font-medium text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                      data-fc-type="collapse"
                    >
                      <div className="bg-green-500/10 rounded-lg flex items-center justify-center h-12 w-12">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-blue-500"
                          shape-rendering="geometricPrecision"
                          text-rendering="geometricPrecision"
                          image-rendering="optimizeQuality"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          viewBox="0 0 414 512.01"
                        >
                          <path d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55C4.04 494.95 0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39C17.06 4.04 26.05 0 35.95 0zm233.33 2.23a13.2 13.2 0 012.49 2.17l136.55 132.31H269.28V2.23zM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98 0-5.51 4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99H91.31zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99H91.31z" />
                        </svg>
                      </div>
                      <h1 className="font-medium mb-4 mt-2">
                        True or False Questions
                      </h1>
                    </div>
                   

                    <div className="my-6"></div>

                    {/* <!-- 3 --> */}
                    <div
                      className="pt-2 inline-flex items-center gap-x-4 w-full font-medium text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                      data-fc-type="collapse"
                    >
                      <div className="bg-orange-500/10 rounded-lg flex items-center justify-center h-12 w-12">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-red-500"
                          shape-rendering="geometricPrecision"
                          text-rendering="geometricPrecision"
                          image-rendering="optimizeQuality"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          viewBox="0 0 414 512.01"
                        >
                          <path d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55C4.04 494.95 0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39C17.06 4.04 26.05 0 35.95 0zm233.33 2.23a13.2 13.2 0 012.49 2.17l136.55 132.31H269.28V2.23zM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98 0-5.51 4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99H91.31zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99H91.31z" />
                        </svg>
                      </div>
                      <h1 className="font-medium mb-4 mt-2">
                        Short Answer Questions
                      </h1>
                    </div>
                    
                    <div className="my-6"></div>
                        
                        {/* <!-- 4 --> */}
                    <div
                      className="pt-2 inline-flex items-center gap-x-4 w-full font-medium text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                      data-fc-type="collapse"
                    >
                      <div className="bg-orange-500/10 rounded-lg flex items-center justify-center h-12 w-12">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-violet-500"
                          shape-rendering="geometricPrecision"
                          text-rendering="geometricPrecision"
                          image-rendering="optimizeQuality"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          viewBox="0 0 414 512.01"
                        >
                          <path d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55C4.04 494.95 0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39C17.06 4.04 26.05 0 35.95 0zm233.33 2.23a13.2 13.2 0 012.49 2.17l136.55 132.31H269.28V2.23zM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98 0-5.51 4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99H91.31zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99H91.31z" />
                        </svg>
                      </div>
                      <h1 className="font-medium mb-4 mt-2">
                        Multi-correct Questions
                      </h1>
                    </div>

                    <div className="my-6"></div>
                    <div
                      className="pt-2 inline-flex items-center gap-x-4 w-full font-medium text-left text-gray-800 transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                      data-fc-type="collapse"
                    >
                      <div className="bg-orange-500/10 rounded-lg flex items-center justify-center h-12 w-12">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-pink-500"
                          shape-rendering="geometricPrecision"
                          text-rendering="geometricPrecision"
                          image-rendering="optimizeQuality"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          viewBox="0 0 414 512.01"
                        >
                          <path d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55C4.04 494.95 0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39C17.06 4.04 26.05 0 35.95 0zm233.33 2.23a13.2 13.2 0 012.49 2.17l136.55 132.31H269.28V2.23zM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98 0-5.51 4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99H91.31zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98H91.31zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99H91.31z" />
                        </svg>
                      </div>
                      <h1 className="font-medium mb-4 mt-2">
                        Fill in the blanks
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1n gap-6 items-center">
                <div
                  className="order-2 lg:order-1 2xl:w-9/12"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <div className="h-12 w-12 bg-primary/10 flex items-center justify-center rounded-lg">
                    <svg
                      className="h-7 w-7 text-primary"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect
                          id="bound"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                        <path
                          d="M10.8226874,8.36941377 L12.7324324,9.82298668 C13.4112512,8.93113547 14.4592942,8.4 15.6,8.4 C17.5882251,8.4 19.2,10.0117749 19.2,12 C19.2,13.9882251 17.5882251,15.6 15.6,15.6 C14.5814697,15.6 13.6363389,15.1780547 12.9574041,14.4447676 L11.1963369,16.075302 C12.2923051,17.2590082 13.8596186,18 15.6,18 C18.9137085,18 21.6,15.3137085 21.6,12 C21.6,8.6862915 18.9137085,6 15.6,6 C13.6507856,6 11.9186648,6.9294879 10.8226874,8.36941377 Z"
                          id="Combined-Shape"
                          fill="currentColor"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M8.4,18 C5.0862915,18 2.4,15.3137085 2.4,12 C2.4,8.6862915 5.0862915,6 8.4,6 C11.7137085,6 14.4,8.6862915 14.4,12 C14.4,15.3137085 11.7137085,18 8.4,18 Z"
                          id="Oval-14-Copy"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>

                  <h1 className="text-3xl/tight font-medium mt-6 mb-4 ">
                    Export your data into any format you like!
                  </h1>
                  <p className="text-gray-500 dark:text-primary_content">
                  At Quizeon, we understand that flexibility and convenience are paramount. That’s why we offer a variety of export options to suit your specific needs.
                  </p>
                  
                </div>

                <div className="relative order-1 lg:order-2">
                  

    <div className="flex justify-center items-center py-16">
      <div className="relative w-96 h-96">
        <div className="absolute flex items-center justify-center w-20 h-20 bg-gray-700 text-white rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span className="text-2xl">{'{ }'}</span>
        </div>
        {icons.map((item, index) => {
          const angle = (index / icons.length) * 2 * Math.PI;
          const x = 150 + 150 * Math.cos(angle);
          const y = 150 + 150 * Math.sin(angle);

          return (
            <div
              key={item.label}
              className="absolute flex items-center justify-center w-16 h-16 bg-blue-500 text-white rounded-full"
              style={{ top: `${y}px`, left: `${x}px` }}
            >
              <FontAwesomeIcon icon={item.icon} size="lg" />
            </div>
          );
        })}
      </div>
    </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
