import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Welcome from "./Welcome";
import Quiz from "./QuestionDisplay";
import Results from "./QuizResult";
import { IoSunny, IoMoon } from "react-icons/io5";
import { serverUrl } from "../../backend/config";


const Modal = ({ isOpen, onClose }) => {
  const modalRef = useRef();

  // Close the modal if the user clicks outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  if (!isOpen) return null;

 return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div ref={modalRef} className="relative bg-white p-6 rounded shadow-lg w-1/3 dark:bg-slate-700">
        {/* Close Button in the top right corner */}
        <button
          onClick={onClose}
          className="absolute top-2 text-4xl right-2 text-gray-500 "
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4">Invalid Code</h2>
        <p className="mb-4">The quiz code you entered is invalid. Please check the code and try again.</p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          OK
        </button>
      </div>
    </div>
  );
};

const Practice = () => {

 
  const [questions, setQuestions] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizCode, setQuizCode] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility


  const handleStartQuiz = async () => {
    let val = [];
    try {
      val = await axios.get(`${serverUrl}/api/quiz/find/${quizCode}`);
      setQuestions(val.data.questions);
      setQuestionType(val.data.questionType);
      setQuizStarted(true);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setIsModalOpen(true); // Show modal if there's an error
    }
  };


  const handleChange = (e) => {
    setQuizCode(e.target.value);
  };

  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? storedTheme : "light";
  });


  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  const handleOptionClick = (option) => {
    const updatedAnswers = [...userAnswers];
    if (questionType === "True or False") {
      updatedAnswers[currentQuestion] = option;
    } else if (questionType === "Multi-correct") {
      const currentAnswers = updatedAnswers[currentQuestion] || [];
      if (currentAnswers.includes(option)) {
        updatedAnswers[currentQuestion] = currentAnswers.filter((ans) => ans !== option);
      } else {
        updatedAnswers[currentQuestion] = [...currentAnswers, option];
      }
    } else {
      updatedAnswers[currentQuestion] = option;
    }
    setUserAnswers(updatedAnswers);
  };

  const handleInputChange = (e) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestion] = e.target.value;
    setUserAnswers(updatedAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setQuizCompleted(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const calculateResults = () => {
    let correct = 0;
    let wrong = 0;
    let skipped = 0;

    questions.forEach((question, index) => {
      const userAnswer = userAnswers[index];
      if (!userAnswer) {
        skipped += 1;
      } else if (questionType === "Multi-correct") {
        const isCorrect =
          userAnswer.length === question.answer.length &&
          userAnswer.every((ans) => question.answer.includes(ans));
        if (isCorrect) correct += 1;
        else wrong += 1;
      } else if (questionType === "Short Answer") {
        if (userAnswer.toLowerCase() === question.answer.toLowerCase()) {
          correct += 1;
        } else {
          wrong += 1;
        }
      } else if (questionType === "True or False") {
        if (userAnswer === Boolean(question.answer)) {
          correct += 1;
        } else {
          wrong += 1;
        }
      } else if (questionType === "Fill in the Blanks") {
        if (userAnswer.toLowerCase() === question.answer.toLowerCase()) {
          correct += 1;
        } else {
          wrong += 1;
        }
      } 
      else {
        if (userAnswer === question.answer) {
          correct += 1;
        } else {
          wrong += 1;
        }
      }

      
    });

    return { correct, wrong, skipped };
  };
  const handleClearAnswer = () => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestion] = null; // Clear the answer
    setUserAnswers(updatedAnswers);
  };
  
  const results = calculateResults();
  // const results = { correct: 0, wrong: 0, skipped: 0 };

  return (
    <div className="dark:bg-dark-body h-fit">
      <div className="p-4 flex flex-row justify-between border-b mb-0">
        <div className="flex-shrink-0 flex items-center">
          <svg
            viewBox="575"
            className="w-10 h-10 justify-center text-center fill-[#7a329d]"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
          >
            <path d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"></path>
          </svg>
          <p className="text-2xl pl-5 font-extrabold font-[Libre-Baskerville] text-[#7a329d]">
            Quizeon
          </p>
        </div>
        <div
          className="transition duration-500 ease-in-out text-gray-800 font-bold py-2 px-4 rounded cursor-pointer"
          onClick={toggleTheme}
        >
          {theme === "light" ? (
            <IoSunny className="w-6 h-6 hover:text-yellow-500 hover:rotate-180 hover:duration-500 duration-500" />
          ) : (
            <IoMoon className="w-6 h-6 text-white hover:text-black -rotate-90 hover:rotate-45 hover:duration-500 duration-500" />
          )}
        </div>
      </div>
      <div>
        {!quizStarted && <Welcome onStartQuiz={handleStartQuiz} quizCode={quizCode} setQuizCode={setQuizCode} handleInputChange={handleChange}/>}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> {/* Add Modal here */}

        {quizStarted && !quizCompleted && (
          <Quiz
            questions={questions}
            type={questionType}
            currentQuestion={currentQuestion}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onOptionClick={handleOptionClick}
            onInputChange={handleInputChange}
            onClearAnswer={handleClearAnswer}
            userAnswers={userAnswers}
          />
        )}
        {quizCompleted && (
          <Results
            results={results}
            questions={questions}
            userAnswers={userAnswers}
          />
        )}
      </div>
    </div>
  );
};

export default Practice;
