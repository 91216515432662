import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ShortAns from "../Question Type/Short_ans";
import MCQType from "../Question Type/MCQ_type";
import TFType from "../Question Type/TF_type";
import MultiCorrect from "../Question Type/Multi-Correct";
import FillBlanks from "../Question Type/FIB";
import { auth, serverUrl } from "../../backend/config";

import axios from "axios";
import { FaCopy, FaRegCopy } from "react-icons/fa";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
Modal.setAppElement("#root"); // Important for screen readers

const QuizModal = ({ isOpen, onRequestClose, quiz, onQuizUpdate }) => {
  const [edit, setEdit] = useState(false);
  const [quizValue, setQuizValue] = useState(quiz);
  const [copied, setCopied] = useState(false);
  const generatedType = quizValue?.generatedType;
  const [uniqueCode, setUniqueCode] = useState();

  const navigate = useNavigate();

  const handleEdit = (option, value) => {
    const updatedResponse = { ...quizValue }; // Update this line to copy the object correctly
    if (option === "title") {
      updatedResponse.quiz_name = value;
    } else if (option === "prompt") {
      updatedResponse.quiz_prompt = value;
    }
    setQuizValue(updatedResponse);
  };

  const handleExport = () => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(quizValue)], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "quiz.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const renderQuizDetails = (quizValue) => {
    switch (quizValue.type) {
      case "Short Answer":
        return (
          <ShortAns
            short_ans={quizValue.questions}
            edit={edit}
            setEdit={setEdit}
          />
        );
      case "MCQ":
        return (
          <MCQType
            mcq_response={quizValue.questions}
            edit={edit}
            setEdit={setEdit}
          />
        );
      case "True or False":
        return (
          <TFType
            tf_response={quizValue.questions}
            edit={edit}
            setEdit={setEdit}
          />
        );
      case "Multi-Correct":
        return (
          <MultiCorrect
            mc_response={quizValue.questions}
            edit={edit}
            setEdit={setEdit}
          />
        );
      case "Fill in the Blanks":
        return (
          <FillBlanks
            fib_response={quizValue.questions}
            edit={edit}
            setEdit={setEdit}
          />
        );
      default:
        return null;
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(uniqueCode);
    setCopied(true);
  };

  useEffect(() => {
    if (quiz) {
      setQuizValue(quiz);
      setUniqueCode(quiz.quiz_id);
    }
  }, [quiz]);

  const updateQuizOnBackend = async () => {
    const uid = auth.currentUser.uid;

    try {
      const response = await axios.put(
        `${serverUrl}/api/quiz/${uid}/${quizValue.quiz_id}`,
        {
          quizId: quizValue.quiz_id,
          questionType: quizValue.questionType,
          updatedQuiz: {
            quiz_name: quizValue.quiz_name,
            quiz_prompt: quizValue.quiz_prompt,
            difficulty_level: quizValue.difficulty_level,
            created_at: quizValue.created_at,
            questions: quizValue.questions,
          },
        }
      );
      console.log("Quiz updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating quiz:", error);
    }
  };

  const handleUpdate = () => {
    if (edit) {
      // Update quiz
      updateQuizOnBackend().then(() => {
        if (onQuizUpdate) {
          onQuizUpdate(); // Notify parent component about the update
        }
      });
    }
    setEdit(!edit);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="pointer-events-auto relative flex max-h-[100%] w-3/4 flex-col overflow-hidden rounded-md border-none bg-white dark:bg-dark-body dark:text-dark-text bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[750px]"
      overlayClassName="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black bg-opacity-50"
    >
      <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
        <h5
          className="text-xl font-medium leading-normal text-surface dark:text-dark-onbackground "
          id="exampleModalScrollableLabel"
        >
          {edit ? (
            <input
              type="text"
              value={quizValue.quiz_name}
              onChange={(e) => handleEdit("title", e.target.value)}
              className="w-full px-2 py-1 border rounded dark:bg-dark-cards"
            />
          ) : (
            <span className="text-center">{quizValue.quiz_name}</span>
          )}
        </h5>

        <button
          type="button"
          className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
          onClick={onRequestClose}
          aria-label="Close"
        >
          <span className="[&>svg]:h-6 [&>svg]:w-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </button>
      </div>

      <div className="relative overflow-y-auto p-4">
        <div className="text-lg font-semibold text-black space-y-2 dark:text-white">
          <div className="flex flex-row justify-evenly md:flex-row md:space-x-8 mb-4">
            <span>Level: {quizValue.difficulty_level}</span>
            <span>Type: {quizValue.type}</span>
            <span>Generated From:{quizValue.generatedType}</span>
          </div>
        </div>
        {generatedType === "text" ? (
          <div className="p-4 mt-4 mb-4 text-lg font-semibold text-black dark:text-white/80 dark:border-white rounded-lg border">
            Quiz Text:
            {edit ? (
              <textarea
                type="text"
                value={quizValue.quiz_prompt}
                onChange={(e) => handleEdit("prompt", e.target.value)}
                className="w-full px-2 py-1 border rounded dark:text-dark-onbackground dark:bg-dark-cards"
              />
            ) : (
              <span className="block py-2">{quizValue.quiz_prompt}</span>
            )}
          </div>
        ) : (
          <div className="p-4 mt-4 mb-4 ">
            <AudioPlayer
              src={quizValue.audioUrl}
              onPlay={(e) => console.log("onPlay")}
              autoPlay={false}
              className="text-lg font-semibold text-black dark:bg-dark-sidebar rounded-lg "
            />
          </div>
        )}
        {renderQuizDetails(quizValue)}
      </div>

      <div className="flex flex-shrink-0 flex-wrap items-center justify-between rounded-b-md border-t-2 border-neutral-100 p-4 dark:border-white/10">
        <div className="flex ">
          {/* Copy Clipboard */}
          <div className="mb-4 p-1 bg-gray-100 border w-fit border-gray-300 rounded flex  justify-center text-center">
            <span className="text-lg font-bold">{uniqueCode}</span>

            {copied ? (
              <FaCopy className="ml-2 mt-1 cursor-pointer" />
            ) : (
              <FaRegCopy
                className="text-gray-500 ml-2 mt-1 cursor-pointer"
                onClick={handleCopy}
                size="20"
              />
            )}
          </div>

          <div>
            <button
              className="mx-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => {
                navigate("/practice");
              }}
            >
              Practice
            </button>
          </div>
        </div>
        <div>
          <button
            className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleUpdate}
          >
            {edit ? "Update" : "Edit"}
          </button>
          <button
            className="mr-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleExport}
          >
            Export Quiz
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default QuizModal;
