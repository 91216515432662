import React from 'react';

class TimerCountDown extends React.Component {
    state = {
        seconds: 10,
        miliseconds: 0
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { miliseconds, seconds, } = this.state


            if (miliseconds === 0) {
                if (seconds === 0) {
                    
                                clearInterval(this.myInterval)}
                             else {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1,
                        miliseconds: 99
                    }))
                }
            } else {
                this.setState(({ miliseconds }) => ({
                    miliseconds: miliseconds - 1
                }))
            }
        }, 10)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { seconds, miliseconds } = this.state
        return (
            <div>
                { seconds === 0 && miliseconds === 0
                    ? <></>
                    : 
                
            <div className="time-box-wrapper">
              
             
              
                {seconds}.{miliseconds}
              
            </div>
            }
           </div>
        )
    }
}

export default TimerCountDown;
