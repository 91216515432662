import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const Results = ({ results, questions, userAnswers }) => {
  const pieData = {
    labels: ["Correct", "Wrong", "Skipped"],
    datasets: [
      {
        data: [results.correct, results.wrong, results.skipped],
        backgroundColor: ["#4caf50", "#f44336", "#ffeb3b"],
      },
    ],
  };
  useEffect(() => {
    console.log("User Answers", userAnswers);
  }, [userAnswers]);

  const score = (results.correct / questions.length) * 100;

  return (
    <div className=" p-8 mx-32 dark:text-dark-buttons">
      <h1 className="text-2xl font-bold text-center mb-4 text-white"> Results</h1>
      <button className="bg-blue-500 text-white p-2 rounded mb-4" onClick={() => window.location.reload()}>Retake Quiz</button>
      <div className="flex flex-col md:flex-row justify-between mb-8">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <Pie data={pieData} options={{ maintainAspectRatio: false }} />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
          <h2 className="text-xl font-bold mb-4 text-white">Score</h2>
          <div className="text-2xl mb-4 text-white" >{score.toFixed(2)}%</div>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 border rounded bg-gray-50">
              <h3 className="font-bold">Total Questions</h3>
              <div>{questions.length}</div>
            </div>
            <div className="p-4 border rounded bg-gray-50">
              <h3 className="font-bold">Correct Answers</h3>
              <div>{results.correct}</div>
            </div>
            <div className="p-4 border rounded bg-gray-50">
              <h3 className="font-bold">Wrong Answers</h3>
              <div>{results.wrong}</div>
            </div>
            <div className="p-4 border rounded bg-gray-50">
              <h3 className="font-bold">Skipped Questions</h3>
              <div>{results.skipped}</div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-xl font-bold mb-4">Detailed Analysis</h2>
      {questions.map((question, index) => (
        <div
          key={index}
          className={`mb-4 p-4 border rounded ${
            userAnswers[index] === question.answer
              ? 'bg-green-100'
              : !userAnswers[index] || (Array.isArray(userAnswers[index]) && userAnswers[index].length === 0)
              ? 'bg-yellow-100'
              : 'bg-red-100'
          }`}
        >
          <div className="font-bold">{question.question}</div>
          {question.type === "Multi-correct" ? (
            <>
              <div className="mt-2">
                Your answers: {(userAnswers[index] || []).join(", ")}
              </div>
              <div>Correct answers: {question.answer.join(", ")}</div>
            </>
          ) : (
            <>
              <div className="mt-2">
                Your answer: {userAnswers[index]}
              </div>
              <div>Correct answer: {question.answer}</div>
            </>
          )}
           <div className="mt-2">
                Your answer: {userAnswers[index]}
              </div>
              <div>Correct answer: {question.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default Results;
