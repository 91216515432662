import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

import "./landing.css";
import Header from "./Header";
import Feature from "./Feature";
import Contact from "./ContactUs";
import { ReactComponent as Design } from "./design.svg";
// Imort the images
import img1 from "./images/Saas_2.png";
import Footer from "./Footer";

export default function Home() {
  const navigate = useNavigate();
  const slides = [img1, img1, img1];
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) { 
      clearTimeout(timeoutRef.current);  
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); 

    return () => {
      resetTimeout();
    };
  }, [currentIndex, slides.length]);

    useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme === "dark") {
        document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }
    , []);
  return (
    <div className="bg-gradient-to-r from-blue-200 to-blue-100 dark:from-[#000000] dark:to-[#130F40] ">
      <Header />

        <section className="-ml-48 pl-0 pt-16 pb-24">
          <div className="container ml-0 pl-0">
            <h1 className="text-center text-5xl font-bold mb-10 text-blue-900 dark:text-white sm:text-6xl lg:text-left lg:text-4xl">
              Imagine, Curate
              <span className="relative">
                <Design />
                <span className="relative bg-gradient-to-r from-primary to-secondaryLight bg-clip-text text-transparent dark:from-primaryLight dark:to-secondaryLight md:px-2">
                  , AI-Questionize
                </span>
              </span>
            </h1>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-16 items-center">
              <div className="order-2 lg:order-1">
                <div className="text-center sm:text-start">
                  <p className=" text-lg text-gray-600 dark:text-gray-300 sm:text-xl">
                    Are you ready to revolutionize the way you create and engage with quizzes?
                  </p>
                  <p className="mt-12 text-lg text-gray-600 dark:text-gray-300 sm:text-xl">
                    Look no further than Quizeon – the all-in-one quiz generation platform designed to empower minds like you!!!
                  </p>
                  <div className="flex flex-wrap items-center gap-5 mt-12">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => navigate("/signup")}
                        className="relative inline-flex items-center px-4 py-2 border bg-primary Z text-sm rounded-full shadow-sm border-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-max font-semibold text-white dark:text-white md:block"
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-1 lg:order-2">
                <div className="relative ">
                  <div className="hidden sm:block">
                    <div className="before:w-24 before:h-24 before:absolute before:bottom-10 before:-start-12 before:bg-dot2"></div>
                    <div className="after:w-24 after:h-24 after:absolute after:-top-10 after:-end-32 2xl:after:end-0 after:rotate-45 after:bg-dot5"></div>
                  </div>
                  <div className="relative w-[780px] h-[500px] max-w-6xl mx-auto">
                    <div
                      className="overflow-hidden relative"
                      onMouseEnter={resetTimeout}
                      onMouseLeave={() => {
                        timeoutRef.current = setTimeout(() => {
                          setCurrentIndex((prevIndex) =>
                            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
                          );
                        }, 5000);
                      }}
                    >
                      <div
                        className="flex transition-transform ease-linear duration-1000 w-full h-full"
                        style={{
                          transform: `translateX(${-currentIndex * 100}%)`,
                        }}
                      >
                        {slides.map((slide, index) => (
                          <div className="w-full flex-shrink-0 flex justify-center items-center" key={index}>
                            <img
                              src={slide}
                              alt={`Slide ${index}`}
                              className="rounded-md w-full" // Adjust the size using Tailwind classes
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="absolute mt-5 inset-x-2 flex items-center justify-center space-x-2">
                      {slides.map((_, idx) => (
                        <button
                          key={idx}
                          className={`h-2 w-2 rounded-full ${currentIndex === idx ? 'bg-green-500' : 'bg-gray-300'}`}
                          onClick={() => setCurrentIndex(idx)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* <Target /> */}
      <Feature id="#feature" />
      <Contact />
      <Footer />
    </div>
  );
}
