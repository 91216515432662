

export const no_of_quest = [
  { id:1 , name:1 },
  { id:2 , name:2 },
  { id:3 , name:3 },
  { id:4 , name:4 },
  { id:5 , name:5 },
  { id:6 , name:6 },
  { id:7 , name:7 },
  { id:8 , name:8 },
  { id:9 , name:9 },
  { id:10 , name:10 },
  { id:11 , name:11 },
  { id:12 , name:12 },
  { id:13 , name:13 },
  { id:14 , name:14 },
  { id:15 , name:15 }
];
export const diff_level = [
  { id:1 , name:"Easy" },
  { id:2 , name:"Medium" },
  { id:3 , name:"Hard" },
];
export const no_of_opt = [
  { id:1 , name:3 },
  { id:2 , name:4 },
  { id:3 , name:5 },
];

export const quest_type = [
  { name: "MCQ", description: "Multiple Choice Questions", available: true },
  {
    name: "Multi-correct",
    description: "Multi-correct Questions",
    available: true,
  },
  {
    name: "Fill in the Blanks",
    description: "Fill in the Blanks Questions",
    available: true,
  },
  {
    name: "Short Answer",
    description: "Short Answer Questions",
    available: true,
  },
  {
    name: "True or False",
    description: "True or False Questions",
    available: true,
  },
];


export default function QuestionType({questionType,
  setQuestionType,
  difficultyLevel,
  setDifficultyLevel,
  numberOfQuestions,
  setNumberOfQuestions,
  numberOfOptions,
  setNumberOfOptions}) {

  const handleQuestionType = (event) => {
    setQuestionType(event.target.value);
  };

  const handleDifficultyLevel = (event) => {
    setDifficultyLevel(event.target.value);
  };

  const handleNumQuestions = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const handleNumOptions = (event) => {
    setNumberOfOptions(event.target.value);
  };

  return (
    <>


<div className="flex flex-wrap justify-between">
  {/* First column */}
  <div className="flex flex-col w-48">
  <div>
  <h3 className="text-base mt-3 text-center font-medium text-gray-900 dark:text-dark-text">
    Question Type
  </h3>

  <div className="relative">
    <select
      id="questionType"
      onChange={handleQuestionType}
      className="border border-gray-300 rounded-md w-full p-2 mt-1 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 appearance-none"
      value={questionType}
    >
      {quest_type.map((quest, index) => (
        <option key={index} value={quest.name}>
          {quest.name}
        </option>
      ))}
    </select>
   
  </div>
</div>


    <div>
      <h3 className="text-base mt-3 text-center font-medium text-gray-900 dark:text-dark-text">
        Difficulty Level
      </h3>

      <select
        id="difficultyLevel"
        onChange={handleDifficultyLevel}
        className="border border-gray-300 rounded-md w-full p-2 mt-1 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 appearance-none"
        value={difficultyLevel}
      >
        {diff_level.map((diff, index) => (
          <option key={index} value={diff.name}>
            {diff.name}
          </option>
        ))}
      </select>
    </div>
  </div>

  {/* Second column */}
  <div className="flex flex-col w-48">
    <div>
      <h3 className="text-base mt-3 text-center font-medium text-gray-900 dark:text-dark-text">
        No. of Questions
      </h3>

      <select
        id="numberOfQuestions"
        onChange={handleNumQuestions}
        className="border border-gray-300 rounded-md w-full p-2 mt-1 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 appearance-none"
        value={numberOfQuestions}
      >
        {no_of_quest.map((quest, index) => (
          <option key={index} value={quest.name}>
            {quest.name}
          </option>
        ))}
      </select>
    </div>
    {(questionType !== "Short Answer" && questionType !== "Fill in the Blanks" && questionType !== "True or False") && (
    <div>
      <h3 className="text-base mt-3 text-center font-medium text-gray-900 dark:text-dark-text ">
        No. of Options
      </h3>

      <select
        id="numberOfOptions"
        onChange={handleNumOptions}
        className="border border-gray-300 rounded-md w-full p-2 mt-1 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 appearance-none"
        value={numberOfOptions}
      >
        {no_of_opt.map((option, index) => (
          <option key={index} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>)}
  </div>
</div>

    </>
  );
}
