import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Host() {
  const [quizName, setQuizName] = useState('');
  const [quizType, setQuizType] = useState('');
  const [numQuestions, setNumQuestions] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/host/1');
    // Handle form submission here
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-1/2">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quizName">
            Quiz Name
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="quizName" type="text" value={quizName} onChange={(e) => setQuizName(e.target.value)} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quizType">
            Quiz Type
          </label>
          <select value={quizType} onChange={(e) => setQuizType(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option value="All">All</option>
            <option value="MCQ">MCQ</option>
            <option value="Multi-correct">Multi-correct</option>
            <option value="Fill in the blanks">Fill in the blanks</option>
            <option value="Short answer">Short answer</option>
            <option value="True or false">True or false</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="numQuestions">
            Number of Questions
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="numQuestions" type="number" value={numQuestions} onChange={(e) => setNumQuestions(e.target.value)} />
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Host Quiz
          </button>
        </div>
      </form>
    </div>
  )
}