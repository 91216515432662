import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaQuestion } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import QuestionType from "../QuestionType";
import MCQType from "../../Question Type/MCQ_type";
import TFType from "../../Question Type/TF_type";
import ShortAns from "../../Question Type/Short_ans";
import FIB from "../../Question Type/FIB";
import Multi from "../../Question Type/Multi-Correct";
import Export_Data from "../Export_Data";
import TimerCountDown from "../Timer";
import axios from "axios";
import AWS from "aws-sdk";
import { auth, serverUrl } from "../../../backend/config";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { FaCopy, FaRegCopy } from "react-icons/fa";
import { SHA256 } from "crypto-js";

//env
// AWS_ACCESS_KEY = "AKIASY2QMWZHGYE72LKR"
// AWS_SECRET_ACCESS_KEY = "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"
// AWS_REGION = "ap-south-1"
// AWS_BUCKET_NAME = "quizeon-storage"

AWS.config.update({
  accessKeyId: "AKIASY2QMWZHGYE72LKR",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const s3 = new AWS.S3();

export default function Gen_Audio() {
  const [genContent, setGenContent] = useState(null);
  const [genError, setGenError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [result_val, setResultVal] = useState([]);
  const [quizName, setQuizName] = useState("");
  const [questionType, setQuestionType] = useState("MCQ");
  const [difficultyLevel, setDifficultyLevel] = useState("Medium");
  const [numberOfQuestions, setNumberOfQuestions] = useState("5");
  const [numberOfOptions, setNumberOfOptions] = useState("3");
  const [genLoading, setGenLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState("");

  const [genQuestionType, setGenQuestionType] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isAnimating, setIsAnimating] = useState(false);
  const texts = ["Uploading audio to the storage", "Analyzing the audio", "Generating Questions ....", "Fourth text"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uniqueCode, setUniqueCode] = useState(null);
  const [copied, setCopied] = useState(false);

  const [uniqueRenderKey, setUniqueRenderKey] = useState(0);

  const uid = auth.currentUser.uid;

  const allowedTypes = [
    "audio/mp3",
    "audio/mp4",
    "audio/mpeg",
    "audio/mpga",
    "audio/m4a",
    "audio/wav",
    "audio/webm",
  ];
  const maxSize = 10 * 1024 * 1024; // 10MB in bytes
  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(uniqueCode);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  useEffect(() => {
    if (isAnimating) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + 1 >= texts.length) {
            clearInterval(interval);
            setIsAnimating(false);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 2000); // Change text every 2 seconds

      return () => clearInterval(interval);
    }

  }, [isAnimating, texts.length]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  //upload
  const uploadToS3 = async (file) => {
    const params = {
      Bucket: "quizeon-storage",
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (err) {
      console.error("Error uploading to S3: ", err);
      setError("Error uploading file. Please try again.");
      setGenLoading(false);
      setIsDisabled(false);
    }
  };

  const handleGenerate = async () => {
    if (!file) {
      setError("Please upload an audio file.");
      return;
    }

    if (quizName === "") {
      setQuizName("Untitled Quiz");
    }

    setGenLoading(true);
    setIsDisabled(true);
    setGenError(false);
    setIsAnimating(true);

    const audioUrl = await uploadToS3(file);
    console.log("audioUrl", audioUrl);

    const res = await axios.post(
      `${serverUrl}/transcribe`,
      {
        audio: audioUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const transcribedText = res.data;

    const awsResponse = await axios.post(
      // "https://flask-service.6jkv49phhb0j2.us-east-1.cs.amazonlightsail.com/item",
      `${serverUrl}/generate`,
      {
        option: questionType,
        no_of_quest: numberOfQuestions,
        no_of_opt: numberOfOptions,
        diff_level: difficultyLevel,
        text: transcribedText,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let awsData = awsResponse.data;

    let awsDataArray;

    try {
      awsDataArray = JSON.parse(awsData);
    } catch (error) {
      console.log("Direct JSON parse failed, attempting manual formatting...");

      // Replace any potential newlines or unwanted characters
      let formattedData = awsResponse.data.replace(/}\s*\n\s*{/g, "},{").trim();

      // Ensure the formatted data is wrapped in square brackets
      formattedData = `[${formattedData}]`;

      // Debug: Log formatted data before parsing
      console.log("Formatted Data:", formattedData);

      // Attempt to parse the manually formatted data
      try {
        awsDataArray = JSON.parse(formattedData);
      } catch (parseError) {
        console.error(
          "Manual formatting JSON parse error:",
          parseError,
          "for data:",
          formattedData
        );
        throw parseError;
      }
    }

    let parsedData = awsDataArray;

    // Update the unique render key to force rerender
    setUniqueRenderKey((prevKey) => prevKey + 1);
    setGenContent(null);
    setGenContent(true);
    setResultVal(parsedData);
    setGenQuestionType(questionType);

    const response = await axios.post(`${serverUrl}/api/quiz/${uid}`, {
      quiz_id: generateKey(),
      quiz_name: quizName,
      quiz_prompt: transcribedText,
      difficulty_level: difficultyLevel,
      created_at: new Date().toISOString(),
      questionType: questionType,
      generatedType: "Audio",
      audioUrl: audioUrl,
      questions: parsedData,
    });
    console.log(response.data);

    setGenLoading(false);
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setError("File type not supported. Please upload an audio file.");
        setFile(null);
      } else if (selectedFile.size > maxSize) {
        setError(
          "File size exceeds the 10MB limit. Please upload a smaller file."
        );
        setFile(null);
      } else {
        setError("");
        setFile(selectedFile);
      }
    }
  };
  const updateQuizOnBackend = async () => {
    const uid = auth.currentUser.uid;
    console.log("Quiz ID:", uniqueCode);

    try {
      const response = await axios.put(
        `/api/quiz/${uid}/${uniqueCode}`,
        {
          quizId: uniqueCode,
          questionType: questionType,
          updatedQuiz: {
            quiz_name: quizName,
            quiz_prompt: text,
            difficulty_level: difficultyLevel,
            created_at: new Date().toISOString(),
            questions: result_val,
          },
        }
      );
      console.log("Quiz updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating quiz:", error);
    }
  };
  const handleSave = () => {
    // updateQuizOnBackend();
    // setIsSaved(!isSaved);
    if (isSaved) {
      updateQuizOnBackend();
    }
    setIsSaved(!isSaved);
  };
  const handleDeleteFile = () => {
    setFile(null);
    setError("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const generateKey = () => {
    const timestamp = Date.now().toString();
    const hash = SHA256(timestamp).toString();
    return hash.slice(0, 6).toUpperCase(); // Return first 6 characters of the hash
  };

  useEffect(() => {
    setUniqueCode(generateKey());
  }, []);

  useEffect(() => {
    console.log("genContent changed:", genContent);
    console.log("result_val updated:", result_val);
    setGenContent(true);
    setResultVal(result_val);
  }, [genContent, result_val]);

  return (
    <>
      <section className="text-gray-600 w-full">
        <div className="container">
          <div className="flex justify-evenly items-center pt-8 pb-8">
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div className="flex justify-between items-center">
                <Link to="/quiz">
                  <button className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                    Back
                  </button>
                </Link>
                <div className="mt-1 relative rounded-md shadow-sm dark:bg-white/10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">Title: </span>
                  </div>
                  <input
                    type="text"
                    name="quiz-title"
                    id="quiz-title"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full dark:bg-white/10 pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Untitled Quiz"
                    autoComplete="off"
                    value={quizName}
                    onChange={(e) => setQuizName(e.target.value)} // Update quizName state
                  />
                </div>
              </div>
            </div>
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8 flex justify-evenly">
              <div className="justify-center">
                <button className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  onClick={() => setIsModalOpen(true)}
                >
                  Practice
                </button>
              </div>
              {!genError && genContent && (
                <div className="justify-center">
                  <button
                    className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    onClick={() => {
                      setIsSaved(!isSaved);
                    }}
                  >
                    {!isSaved ? "Edit" : "Save"}
                  </button>
                </div>
              )}
              <div className="justify-center">
                <Export_Data response={result_val} />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div className="w-full float block mb-5">
                <QuestionType
                  questionType={questionType}
                  setQuestionType={setQuestionType}
                  difficultyLevel={difficultyLevel}
                  setDifficultyLevel={setDifficultyLevel}
                  numberOfQuestions={numberOfQuestions}
                  setNumberOfQuestions={setNumberOfQuestions}
                  numberOfOptions={numberOfOptions}
                  setNumberOfOptions={setNumberOfOptions}
                />
              </div>
              <div className="flex items-start space-x-2 rounded-lg border dark:bg-white/10 dark:border-white  border-dashed border-gray-900/25">
                <div className="min-w-0 flex-1">
                  <div className="relative ">
                    <div
                      className="mt-2 flex justify-center border-b border-dashed px-6 py-10 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 cursor-pointer"
                      onClick={() =>
                        fileInputRef.current && fileInputRef.current.click()
                      }
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                      />
                      {file === null ? (
                        <div className="text-center ">
                          <div className="flex items-center justify-center text-gray-600">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="30px"
                              height="30px"
                              viewBox="0 0 104.23 122.88"
                            >
                              <g>
                                <path
                                  className="st0"
                                  d="M87.9,78.04c2.74-0.48,5.33-0.4,7.6,0.13V24.82L39.05,41.03v61.95c0.03,0.34,0.05,0.69,0.05,1.03 c0,0,0,0.01,0,0.01c0,8.34-8.75,16.62-19.55,18.49C8.76,124.37,0,119.12,0,110.77c0-8.34,8.76-16.62,19.55-18.48 c4.06-0.7,7.84-0.39,10.97,0.71l0-76.26h0.47L104.04,0v85.92c0.13,0.63,0.2,1.27,0.2,1.91c0,0,0,0,0,0.01 c0,6.97-7.32,13.89-16.33,15.44c-9.02,1.56-16.33-2.83-16.33-9.8C71.57,86.51,78.88,79.59,87.9,78.04L87.9,78.04L87.9,78.04z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label className="relative cursor-pointer rounded-md font-semibold text-indigo-600 ">
                              <span>Upload a file</span>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs leading-5 text-gray-600">
                            MP3, MP4 up to 10MB
                          </p>
                        </div>
                      ) : (
                        <div className="text-center">
                          <p>File Uploaded: {file.name}</p>
                          <button
                            className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:bg-red-600"
                            onClick={handleDeleteFile}
                          >
                            Delete File
                          </button>
                        </div>
                      )}
                    </div>
                    {error && (
                      <p className="text-red-700 text-center">{error}</p>
                    )}
                  </div>
                  <div className=" bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between   border-gray-300 border-t-0 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                    <div className="flex items-center">
                      {genLoading && (
                        <div>
                          <TimerCountDown />
                        </div>
                      )}
                      {genLoading && (
                        <div className="w-6 mx-3 h-6 border-4 border-gray-300 border-t-4 border-t-gray-800 rounded-full animate-spin"></div>
                      )}
                      <button
                        className="inline-flex items-center px-4 py-2 disabled:bg-slate-500 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={!isDisabled && genLoading}
                        onClick={handleGenerate}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div
                key={uniqueRenderKey}
                className="overflow-y-auto overflow-x-auto h-[36rem]"
              >
                {!genError ? (
                  <>
                    {genContent === null && (
                      <div className="flex justify-center items-center h-full">
                        <FaQuestion className="h-20 w-20 text-gray-300" />
                        Questions will be displayed here
                        {currentIndex >= 0 && (
                          <p
                            key={currentIndex}
                            className="opacity-0 animate-fadeRight"
                          >
                            {texts[currentIndex]}
                          </p>
                        )}
                      </div>
                    )}
                    {genContent === false && (
                      <div className="flex justify-center items-center h-full">
                        <span className="loadanim"></span>
                      </div>
                    )}
                    {genContent && (
                      <>
                        {genQuestionType === "MCQ" && (
                          <MCQType
                            mcq_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "True or False" && (
                          <TFType
                            tf_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Short Answer" && (
                          <ShortAns
                            short_ans={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Fill in the Blanks" && (
                          <FIB
                            fib_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Multi-correct" && (
                          <Multi
                            mc_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                  
                      </>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <FiAlertTriangle className="h-20 w-20 text-red-500 mb-3" />
                    <span className="text-red-500">
                      {errMessage ? errMessage : "An error occurred"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (<Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Unique Code Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'static',
            inset: 'unset',
            width: '90%',
            maxWidth: '500px',
            margin: '0 auto',
            padding: '0',
            border: 'none',
            background: 'none',
            overflow: 'unset',
          },
        }}
      >
        <div className="p-4 bg-white rounded shadow-lg max-w-lg mx-auto ">
          <div className="flex flex-row border-b-2 justify-between mb-4">
            <div className="text-xl font-bold ">Share</div>
            <div> <button
          type="button"
          className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
          onClick={closeModal}
          aria-label="Close"
        >
          <span className="[&>svg]:h-6 [&>svg]:w-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </button></div>
          </div>
       
          {/* <h2 className="text-xl font-bold mb-4">Your Unique Code</h2> */}
          <div className="mb-4 p-2 bg-gray-100 border w-fit border-gray-300 rounded flex justify-center text-center">
              <span className="text-lg font-bold">{uniqueCode}</span>
              
            {copied? <FaCopy className="ml-2 mt-1 cursor-pointer"/> : <FaRegCopy className="text-gray-500 ml-2 mt-1 cursor-pointer" onClick={handleCopy} size="20" />}
          </div>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => navigate(`/practice`)}
          >
            Practice
          </button>
        </div>
      </Modal>)}
    </>
  );
}
