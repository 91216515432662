
import { Link } from 'react-router-dom';


export default function Dummy() {
    return (
        <>
          <div className="flex flex-col text-center items-center p-4 justify-center h-screen m-auto max-w-2xl">
          You need to sign in or sign up before continuing.
            <div className="flex flex-col justify-between items-center pt-20">
              <div> Join the Quizeon revolution and transform the way you teach, learn, and engage.
              </div>
                 
              <div className="pt-4">Start creating quizzes now!</div>
              {/* Create two button sign in and sign up */}
              <div className="flex gap-4 mt-24">
                <Link to="/signin" className="bg-[#26A612] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Sign In
                </Link>
                <Link to="/signup" className="bg-[#26A612] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Sign Up
                </Link>
                </div>
              </div>




          </div>
        </>
    )
}