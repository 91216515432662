import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { auth } from "../../backend/config";
import { serverUrl } from "../../backend/config";

import QuizModal from "./Modal"; // Import the QuizModal component

export default function Quiz(props) {
  const [query, setQuery] = useState("");
  const [filterOption, setFilterOption] = useState("All");

  const [mcq, setMcq] = useState([]);
  const [shortAnswer, setShortAnswer] = useState([]);
  const [trueFalse, setTrueFalse] = useState([]);
  const [multiCorrect, setMultiCorrect] = useState([]);
  const [fillBlanks, setFillBlanks] = useState([]);


  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [combinedQuizzes, setCombinedQuizzes] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);

  const openModal = (index) => {
    setSelectedQuiz(filteredQuizzes[index]);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedQuiz(null);
    setModalIsOpen(false);
  };

  // const renderQuizDetails = (quiz) => {
  //   switch (quiz.type) {
  //     case 'Short Answer':
  //       return <Short_ans short_ans={quiz.questions} />;
  //     case 'MCQ':
  //       return <MCQ_type mcq_response={quiz.questions} />;
  //     case 'True or False':
  //       return <TF_type tf_response={quiz.questions} />;
  //     case 'Multi-Correct':
  //       return <MultiCorrect mc_response={quiz.questions} />;
  //     case 'Fill in the Blanks':
  //       return <FillBlanks fib_response={quiz.questions} />;
  //     default:
  //       return null;
  //   }
  // };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filterData(query);
  };

  const filterData = (searchQuery) => {
    if (!searchQuery) {
      applyFilter(filterOption);
      return;
    }

    let filtered;
    if (filterOption === "All") {
      filtered = combinedQuizzes.filter(
        (item) =>
          item.quiz_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.type.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      filtered = combinedQuizzes.filter(
        (item) =>
          item.type.toLowerCase() === filterOption.toLowerCase() &&
          (item.quiz_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.type.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
    const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setFilteredQuizzes(sortedData);
  };

  const applyFilter = (option) => {
    if (option === "All") {
      setFilteredQuizzes(combinedQuizzes);
    } else {
      const filtered = combinedQuizzes.filter(
        (item) => item.type.toLowerCase() === option.toLowerCase()
      );
      const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setFilteredQuizzes(sortedData);
    }
  }; 

  const handleFilterChange = (e) => {
    const option = e.target.value;
    setFilterOption(option);
  };

  const getQuizzes = async () => {
    const uid = auth.currentUser.uid;

    try {
      const response = await axios.get(`${serverUrl}/api/quiz/` + uid);

      // console.log(response.data);
      const fetchedData = response.data;

      setMcq(fetchedData.mcq);
      setShortAnswer(fetchedData.shortAnswer);
      setTrueFalse(fetchedData.trueFalse);
      setMultiCorrect(fetchedData.multiCorrect);
      setFillBlanks(fetchedData.fillInTheBlanks);

      const combined = [
        ...fetchedData.shortAnswer.map((quiz) => ({ ...quiz, type: 'Short Answer' })),
        ...fetchedData.mcq.map((quiz) => ({ ...quiz, type: 'MCQ' })),
        ...fetchedData.trueFalse.map((quiz) => ({ ...quiz, type: 'True or False' })),
        ...fetchedData.multiCorrect.map((quiz) => ({ ...quiz, type: 'Multi-Correct' })),
        ...fetchedData.fillInTheBlanks.map((quiz) => ({ ...quiz, type: 'Fill in the Blanks' }))
      ];

      setCombinedQuizzes(combined);
      const sortedData = combined.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      setFilteredQuizzes(sortedData);
      // setFilteredQuizzes(combined); // Initialize filtered quizzes with all quizzes
    } catch (error) {
      console.error(error);
    }
  };

  const toIST = (utcString) => {
    const options = { timeZone: 'Asia/Kolkata', hour12: false };
    return new Date(utcString).toLocaleString('en-US', options);
  };

  useEffect(() => {

    getQuizzes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  []); // Run once on component mount to fetch quizzes

  const handleQuizUpdate = () => {
    getQuizzes(); // Refresh the quiz list after update
    closeModal();
  };

  useEffect(() => {
    filterData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filterOption, combinedQuizzes]); // Re-run filtering whenever query, filterOption, or combinedQuizzes change

  return (
    <div style={{ marginBottom: "50px" }} className="">
      <>
        <div className="flex items-center justify-evenly gap-10 ml-10 mr-10 mt-6 ">
          <form onSubmit={handleSubmit} className="flex items-center">
            <Link to="/dashboard">
              <button className="px-4 py-2 bg-blue-500 text-white dark:bg-dark-text-btn dark:hover:bg-slate-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                Back
              </button>
            </Link>
          </form>
          <form onSubmit={handleSubmit} className="flex items-center">
            <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={handleInputChange}
              className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none dark:bg-dark-text-btn/40 dark:hover:bg-slate-300 focus:border-blue-500"
            />
          </form>
          <div>
            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="px-4 py-2 bg-blue-500 text-white dark:bg-dark-text-btn dark:hover:bg-slate-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              <option value="All">All</option>
              <option value="MCQ">MCQ</option>
              <option value="Multi-correct">Multi-correct</option>
              <option value="Fill in the blanks">Fill in the blanks</option>
              <option value="Short answer">Short answer</option>
              <option value="True or false">True or false</option>
            </select>
          </div>
          <button className="px-4 py-2 bg-blue-500 text-white dark:bg-dark-text-btn dark:hover:bg-slate-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
            <Link to="/quiz">
              <span className="mr-1">&#43;</span> Create a Quiz
            </Link>
          </button>
        </div>
        <hr className="mt-10" />
        <div>
          {filteredQuizzes.length > 0 ? (
            <div className="mt-10 mx-10">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ">
                {filteredQuizzes.map((quiz, index) => (
                  <div
                    key={index}
                    onClick={() => openModal(index)}
                    className="relative cursor-pointer p-6 bg-white dark:bg-dark-cards rounded-lg shadow-lg flex flex-col items-center text-center transition duration-300 transform hover:scale-105"
                  >
                    <div className="absolute top-2 left-2 text-sm text-blue-500 dark:text-dark-onbackground font-semibold rounded-xl p-1.5">
                      {quiz.generatedType.toUpperCase()} 
                    </div>
                    <div className="absolute top-2 right-2 text-sm text-blue-500 dark:text-dark-onbackground font-extrabold rounded-xl p-1.5">
                      {quiz.type}
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800  dark:text-dark-text mt-7">
                      {quiz.quiz_name}
                    </h3>
                    <div className="absolute bottom-2 left-2 text-sm text-blue-500 dark:text-dark-onbackground font-extrabold rounded-xl p-1.5">
                      {quiz.difficulty_level}
                    </div>
                    <div className="flex-grow flex flex-col justify-center items-center">
                      <p className="text-sm text-gray-600">
                        Created: {toIST(quiz.created_at)}
                      </p>
                    </div>
                    <p className="text-sm text-gray-600 mb-7">
                      {quiz.total_marks}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center min-h-screen m-10 border-dotted border-2 border-gray-400">
              <svg
                className="mx-auto h-12 w-12 text-gray-400 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2z"
                />
              </svg>
              <h1 className="mt-6 text-xl font-extrabold text-gray-900 dark:text-dark-onbackground">
                No quizzes found
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                Try another filter or create a new quiz.
              </p>
              <div className="mt-10">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md dark:bg-dark-text-btn dark:hover:bg-slate-500 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => navigate("/quiz")}
                >
                  <FaPlus className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  New Quiz
                </button>
              </div>
            </div>
          )}
        </div>
        {modalIsOpen && (
         <QuizModal
         isOpen={modalIsOpen}
         onRequestClose={closeModal}
         quiz={selectedQuiz}
         onQuizUpdate={handleQuizUpdate} // Pass the update function
       />
      )}

      </>
    </div>
  );
}

