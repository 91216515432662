import React from "react";

const Quiz = ({
  questions,
  type,
  currentQuestion,
  onNext,
  onPrevious,
  onOptionClick,
  onInputChange,
  onClearAnswer, // Add this new prop to clear the answer
  userAnswers,
}) => {
  const question = questions[currentQuestion];

  const handleClear = () => {
    onClearAnswer(); // Call the parent function to clear the answer
  };

  return (
    <div className="dark:bg-dark-body">
      <div className="flex">
        <div className="w-1/4 h-screen rounded-r-lg p-4 dark:bg-dark-sidebar">
          <div className="text-lg font-bold mb-4">Quiz Questions</div>
          <div className="grid grid-cols-4 gap-5">
            {questions.map((_, index) => (
              <button
                key={index}
                className={`px-4 py-2 text-center rounded ${currentQuestion === index ? 'bg-primary text-white' : 'bg-gray-200 text-black'}`}
                onClick={() => onOptionClick(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
        <div className="w-3/4 p-4 rounded flex flex-col">
          <div>
            <div className="flex items-center">
              <h1 className="text-lg lg:text-base font-semibold">
                Question {currentQuestion + 1}) {question.question}
              </h1>
            </div>
            {type === "MCQ" && (
              <div className="flex flex-col space-y-2 mb-4">
                {question.options.map((option, index) => (
                  <button
                    key={index}
                    className={`flex items-center px-4 py-2 border rounded hover:bg-gray-200 ${
                      userAnswers[currentQuestion] === option ? "bg-blue-200" : ""
                    }`}
                    style={{ width: "200px" }}
                    onClick={() => onOptionClick(option)}
                  >
                    <span className="mr-2">{String.fromCharCode(65 + index)}.</span> {option}
                  </button>
                ))}
              </div>
            )}
            {type === "Fill in the Blanks" && (
              <div className="flex flex-col mb-4">
                <input
                  type="text"
                  className="px-4 py-2 border rounded"
                  value={userAnswers[currentQuestion] || ""}
                  onChange={onInputChange}
                />
              </div>
            )}
            {type === "Multi-correct" && (
              <div className="flex flex-col space-y-2 mb-4">
                {question.options.map((option, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={(userAnswers[currentQuestion] || []).includes(option)}
                      onChange={() => onOptionClick(option)}
                    />
                    <label className="flex items-center">
                      <span className="mr-2">{String.fromCharCode(65 + index)}.</span> {option}
                    </label>
                  </div>
                ))}
              </div>
            )}
            {type === "Short Answer" && (
              <div className="flex flex-col space-y-2 mb-4">
                <textarea
                  rows={4}
                  className="px-4 py-2 border rounded"
                  value={userAnswers[currentQuestion] || ""}
                  onChange={onInputChange}
                />
              </div>
            )}
            {type === "True False" && (
              <div className="flex flex-col mb-4">
                {["True", "False"].map((option) => (
                  <label
                    key={option}
                    className={`flex items-center bg-gray-100 text-gray-700 rounded-md px-3 py-2 my-3 hover:bg-indigo-300 cursor-pointer`}
                  >
                    <input
                      type="radio"
                      name="trueFalse"
                      id={option}
                      value={option.toLowerCase()}
                      checked={userAnswers[currentQuestion] === option}
                      onChange={() => onOptionClick(option)}
                    />
                    <span className="pl-2">{option}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <button
              className="px-4 py-2 bg-gray-200 rounded dark:text-dark-text-btn"
              onClick={onPrevious}
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded dark:text-dark-text-btn"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              className="px-4 py-2 bg-gray-200 rounded dark:text-dark-text-btn"
              onClick={onNext}
            >
              {currentQuestion === questions.length - 1 ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
