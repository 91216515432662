import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun,NumberFormat  ,Header, Footer, AlignmentType, PageNumber } from "docx";

// Function to get the current date and time in the specified format
const getCurrentDateTime = () => {
    const now = new Date();
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false 
    };
    return now.toLocaleString('en-GB', options).replace(',', ' |');
};

export const generateDoc = async (questions) => {
    const currentDateTime = getCurrentDateTime();

    const doc = new Document({
        sections: [
            {
                properties: {
                    page: {
                        pageNumbers: {
                            start: 1,
                            formatType: NumberFormat.DECIMAL,
                        },
                    },
                },
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Generated on Quizeon",
                                        bold: true,
                                        size: 24, // Font size 12
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `Generated on Quizeon ${currentDateTime} | Page `,
                                    }),
                                    new TextRun({
                                        children: [`${PageNumber.CURRENT}`],
                                    }),
                                    new TextRun({
                                        text: " of ",
                                    }),
                                    new TextRun({
                                        children: [`${PageNumber.TOTAL_PAGES}`],
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "Quiz",
                                bold: true,
                                size: 48, // Font size 24
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "Questions",
                                bold: true,
                                size: 36, // Font size 18
                            }),
                        ],
                    }),
                    ...questions.map((item, index) => [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `Question ${index + 1}: ${item.question}`,
                                    bold: true,
                                    size: 28, // Font size 14
                                }),
                            ],
                        }),
                        new Paragraph({
                            children: item.options.map((option, idx) => new TextRun({
                                text: `${String.fromCharCode(65 + idx)}. ${option}`,
                                break: idx < item.options.length  ? 1 : 0,
                                size: 24, // Font size 12
                            })),
                        }),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: `Answer: ${item.answer}`,
                                    italic: true,
                                    size: 24, // Font size 12
                                }),
                            ],
                        }),
                        new Paragraph({}), // Adding a blank paragraph for spacing
                    ]).flat(),
                ],
            },
        ],
    });

    // Export the file as a .docx file
    Packer.toBlob(doc).then(blob => {
        saveAs(blob, "quiz.docx");
        console.log("Document created successfully");
    });
};
