// ResultsPage.js
import React from "react";
import logo from "../Home/logo.svg";
import { useLocation,useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import { useEffect } from "react";
export default function Result() {
  const location = useLocation();
  const questions = location.state.questions;
  const userResponses = location.state.userResponses;
  console.log("questions:" + questions);
  const navigate = useNavigate();

  let correctCount = null;
  let incorrectCount = null;
  let answeredCount = null;

  if (!questions || !Array.isArray(userResponses)) {
    correctCount = 0;
    incorrectCount = 0;
    answeredCount = 0;
  } else {
    correctCount = questions.filter(
      (question, index) => userResponses[index] === question.correctAnswer
    ).length;
    incorrectCount = questions.filter(
      (question, index) => userResponses[index] !== question.correctAnswer
    ).length;
    answeredCount = userResponses.filter(
      (response) => response !== null
    ).length;
    // const evaluateTest = () => {
    //   let score = 0;
    //   questions.forEach((question, index) => {
    //     if (userResponses[index] === question.correctAnswer) {
    //       score++;
    //     }
    //   });
    //   return score;
    // };

  }
  useEffect(() => {
    const ctx = document.getElementById("pie-chart").getContext("2d");
    new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Correct", "Incorrect", "Unanswered"],
        datasets: [
          {
            label: "Result",
            data: [
              correctCount,
              incorrectCount,
              questions.length - answeredCount,
            ],
            backgroundColor: ["#68D391", "#FC8181", "#A0AEC0"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }, [correctCount, incorrectCount, questions, answeredCount]);

  return (
    <>
      <header className="flex justify-between items-center w-full px-6 py-4 bg-white shadow-md">
        <div className="flex items-center space-x-2">
          <img src={logo} alt="Logo" className="h-8" />{" "}
          {/* Adjust height as needed */}
          <h1 className="text-lg font-semibold">Quiz App</h1>
        </div>
        <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={()=>{navigate('/signin')}}>
          Login
        </button>
      </header>
      <div className="container mx-auto p-4">
        <div className="flex items-center justify-evenly mb-4">
          <h2 className="text-lg text-center font-bold mb-4">
            Question Type: MCQ
          </h2>
          <h2 className="text-lg text-center font-bold mb-4">Results</h2>
        </div>
        <div className="mb-12">
          <canvas id="pie-chart" width="200" height="200"></canvas>
        </div>
        <div className="flex items-center justify-evenly mb-4">
            <h2 className="text-lg text-center font-bold mb-4">
                Score: {correctCount}/{questions.length}
            </h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Question</th>
                <th className="border border-gray-300 px-4 py-2">
                  Your Answer
                </th>
                <th className="border border-gray-300 px-4 py-2">Marked</th>
                <th className="border border-gray-300 px-4 py-2">
                  Correct Answer
                </th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-4 py-2">
                    {question.text}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {userResponses[index]}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {question.status}
                  </td>{" "}
                  {/* Assuming 'status' indicates 'marked' */}
                  <td className="border border-gray-300 px-4 py-2">
                    {question.correctAnswer}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
