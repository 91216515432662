import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import {
  
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { auth, provider } from "../../backend/config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios"; // Import axios for making API requests
import { serverUrl } from "../../backend/config";

export default function Signup({ setAuthToken }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [isSignupLoading, setIsSignupLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard");
        setAuthToken(true);
      }
      else {
        setAuthToken(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  const gClick = async () => {
    try {
      setIsLoading(true);
      // await signInWithRedirect(auth, provider);
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        // Save user data to the database
        await axios.post(`${serverUrl}/api/user`, {
          name: result.user.displayName,
          email: result.user.email,
          type: "google",
          uid: result.user.uid,
        });
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error initiating Google Sign-In:", error);
      setIsLoading(false);
    }
  };

  const fbClick = async () => {
    try {
      // await signInWithRedirect(auth, fbprovider);
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        // Save user data to the database
        await axios.post(`${serverUrl}/api/user`, {
          name: result.user.displayName,
          email: result.user.email,
          type: "facebook",
          uid: result.user.uid,
        });
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error initiating Facebook Sign-In:", error);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!name || !email || !password || !confirmPassword) {
      Swal.fire("Error", "All fields are required.", "error");
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire("Error", "Passwords do not match.", "error");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire("Error", "Please enter a valid email address.", "error");
      return;
    }
    if (password.length < 6) {
      Swal.fire(
        "Error",
        "Password should be at least 6 characters long.",
        "error"
      );
      return;
    }
    try {
      setIsSignupLoading(true);
      alert("Signing up...");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // await user.updateProfile({
      //   displayName: name,
      // });

      if (user) {
        try {
          console.log("calling post to DB");
          await axios.post(`${serverUrl}/api/user`, {
            name: name,
            email: email,
            type: "normal",
            uid: user.uid,
          });
          navigate("/dashboard");
        } catch (error) {
          console.error("Error saving user to MongoDB:", error);
          Swal.fire("Error", "Failed to save user to database", "error");
          setIsSignupLoading(false);
        }
      } else {
        Swal.fire("Error", "User not created", "error");
        setIsSignupLoading(false);
      }
    } catch (error) {
      console.error("Error signing up:", error);
      Swal.fire("Error", error.message, "error");
      setIsSignupLoading(false);
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme === "dark") {
        document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }
    , []);

  return (
    <>
      
      <div class="flex w-screen flex-wrap text-slate-800 bg-gray-50 dark:bg-gray-900">
        <div class="relative hidden h-screen select-none flex-col justify-center border-r text-center md:flex md:w-1/2">
          <div className="mx-auto flex max-w-md flex-col rounded-lg lg:max-w-screen-xl lg:flex-row">
            <div class="max-w-2xl px-4 lg:pr-24 ">
              {/* <p class="mb-16 text-lg text-gray-600 dark:text-dark-text">
                Harness the power of AI to generate diverse and challenging quiz
                questions automatically. The system analyzes provided content or
                specified topics to create relevant and varied questions,
                including multiple-choice, true/false, short answer, and more.
              </p> */}
              <div class="mb-5 flex font-medium">
                <div class="mr-4">
                  
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-7 w-7 text-blue-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                    />
                  </svg>
                </div>
                <div class="">
                  <p class="mb-2 dark:text-white">Automated Question Generation</p>
                  <span class="font-normal text-gray-600 dark:text-dark-text">
                    Generate diverse quiz questions automatically, including
                    multiple-choice, true/false, short answer, and more,
                    ensuring consistent difficulty and relevance.
                  </span>
                </div>
              </div>
              <div class="mb-5 flex font-medium">
                <div class="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-7 w-7 text-blue-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
                <div class="">
                  <p class="mb-2 dark:text-white">Multi-Format Content Integration</p>
                  <span class="font-normal text-gray-600 dark:text-dark-text">
                    Add text, audio, and PDF files to create quizzes, providing
                    a richer and more engaging learning experience.
                  </span>
                </div>
              </div>
              <div class="mb-5 flex font-medium">
                <div class="mr-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-7 w-7 text-blue-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                    />
                  </svg>
                </div>
                <div class="">
                  <p class="mb-2 dark:text-white">Easy Export and Sharing</p>
                  <span class="font-normal text-gray-600 dark:text-dark-text">
                    Export quizzes to PDF and DOC formats for convenient offline
                    access and distribution.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- <img class="mx-auto w-11/12 max-w-lg rounded-lg object-cover" src="/images/SoOmmtD2P6rjV76JvJTc6.png" /> --> */}
        </div>
        <div class="flex w-full flex-col md:w-1/2">
          <div class="flex justify-center pt-12 md:justify-start md:pl-12">
            <svg
              viewBox="575"
              className="w-10 h-10 justify-center text-center fill-[#7a329d]"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
            >
              <path d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"></path>
            </svg>
            <p className="text-2xl pl-5 font-extrabold font-[Libre-Baskerville] text-[#7a329d]">
              Quizeon
            </p>
          </div>
          <div class="my-auto mx-auto flex flex-col justify-center px-6 pt-4 md:justify-start lg:w-[28rem]">
            <p class="text-center text-3xl font-bold md:text-left md:leading-tight dark:text-white">
              Create your free account
            </p>
            <p class="mt-6 text-center font-medium md:text-left mb-6 dark:text-dark-text">
              Already using Quizeon?
              <a href="#" class="whitespace-nowrap font-semibold text-blue-700 ml-5">
                Login here
              </a>
            </p>
            <div className="mt-1 grid grid-cols-2 gap-3">
              <div>
                <button
                  onClick={gClick}
                  disabled={isLoading}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white dark:hover:bg-slate-500 dark:text-white dark:bg-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="mr-5"> Google</span>
                  <FcGoogle className="w-5 h-5" />
                </button>
              </div>
              <div>
                <button
                  onClick={fbClick}
                  disabled={isLoading}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white dark:text-white dark:hover:bg-slate-500 dark:bg-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="mr-5"> Facebook</span>
                  <FaFacebook className="w-5 h-5 text-[#1877F2]" />
                </button>
              </div>
            </div>
            <div class="relative mt-8 flex h-px place-items-center bg-gray-200 ">
              <div class="absolute left-1/2 h-6 -translate-x-1/2 bg-white px-4 dark:bg-gray-900 text-center text-sm text-gray-500">
                Or use email instead
              </div>
            </div>
            <form class="flex flex-col items-stretch pt-3 md:pt-8">
              <div class="flex flex-col pt-4">
                <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    required
                    class="w-full flex-shrink appearance-none border-gray-300 bg-white  py-2 px-4 text-base text-gray-700 dark:bg-gray-700 dark:text-white placeholder-gray-400 focus:outline-none"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div class="flex flex-col pt-4">
                <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    class="w-full flex-shrink appearance-none border-gray-300 bg-white dark:bg-gray-700 dark:text-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div class="flex flex-col pt-4">
                <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    class="w-full flex-shrink appearance-none border-gray-300 bg-white dark:bg-gray-700 dark:text-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="mb-4 flex flex-col pt-4">
                <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirm-password"
                    required
                    class="w-full flex-shrink appearance-none border-gray-300 bg-white dark:bg-gray-700 dark:text-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="block">
                <input
                  class="mr-2 h-5 w-5 appearance-none rounded border border-gray-300 bg-contain bg-no-repeat align-top text-black shadow checked:bg-blue-600 focus:border-blue-600 focus:shadow"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="inline-block" for="remember-me">
                  {" "}
                  I agree to the{" "}
                  <a class="underline" href="#">
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <button
                                            type="submit"
                                            className="w-full mt-6 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                            onClick={handleSignup}
                                        >
                                            {isSignupLoading ? "Signing up..." : "Sign up"}
                                        </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
