import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition, Listbox } from "@headlessui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";

import { auth } from "../backend/config";
import { BsFillPeopleFill } from "react-icons/bs";
import { useEffect } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { MdArrowDropDown } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { IoSunny, IoMoon } from "react-icons/io5";


const navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mr-5"
      >
        <path
          className="fill-purple-700 dark:fill-white"
          d="M13.7087 3.30729C12.6049 2.90591 11.395 2.90591 10.2913 3.30729L3.24266 5.87042C1.48732 6.50872 1.4873 8.99128 3.24266 9.62959L10.2913 12.1927C11.395 12.5941 12.6049 12.5941 13.7087 12.1927L20.7573 9.62959C22.5126 8.99128 22.5126 6.50873 20.7573 5.87042L13.7087 3.30729Z"
        ></path>
        <path
          className="fill-purple-300 "
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.05781 12.395C2.24283 11.8746 2.81467 11.6028 3.33504 11.7878L11.33 14.6304C11.7634 14.7845 12.2367 14.7845 12.67 14.6304L20.665 11.7878C21.1854 11.6028 21.7572 11.8746 21.9422 12.395C22.1273 12.9154 21.8554 13.4872 21.335 13.6722L13.3401 16.5149C12.4733 16.8231 11.5268 16.8231 10.66 16.5149L2.66502 13.6722C2.14465 13.4872 1.87279 12.9154 2.05781 12.395Z"
        ></path>
        <path
          className="fill-purple-700 dark:fill-white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.05781 16.645C2.24283 16.1246 2.81467 15.8528 3.33504 16.0378L11.33 18.8804C11.7634 19.0345 12.2367 19.0345 12.67 18.8804L20.665 16.0378C21.1854 15.8528 21.7572 16.1246 21.9422 16.645C22.1273 17.1654 21.8554 17.7372 21.335 17.9222L13.3401 20.7649C12.4733 21.0731 11.5268 21.0731 10.66 20.7649L2.66502 17.9222C2.14465 17.7372 1.87279 17.1654 2.05781 16.645Z"
        ></path>
      </svg>
    ),
    current: false,
  },
  {
    name: "Generate Quiz",
    href: "/quiz",
    icon: (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mr-5"
      >
        <path
          className="fill-purple-700 dark:fill-white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z"
        ></path>
        <path
          className="fill-purple-300"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 10C3 9.44772 3.44772 9 4 9H14C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H4C3.44772 11 3 10.5523 3 10Z"
        ></path>
        <path
          className="fill-purple-700 dark:fill-white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H4C3.44772 15 3 14.5523 3 14Z"
        ></path>
        <path
          className="fill-purple-300"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 18C3 17.4477 3.44772 17 4 17H14C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H4C3.44772 19 3 18.5523 3 18Z"
        ></path>
      </svg>
    ),
    current: false,
    subMenu: true,
    subMenuItems: [
      {
        name: "From Content",
        href: "/generate-quiz/content/" ,
        icon: (
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-5"
          >
            <rect
              className="fill-purple-300 dark:fill-white"
              x="3"
              y="3"
              width="18"
              height="19"
              rx="3"
            ></rect>
            <path
              className="fill-purple-600 "
              d="M14 3C14 1.89543 13.1046 1 12 1C10.8954 1 10 1.89543 10 3H8V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3H14Z"
            ></path>
            <path
              className="fill-purple-600"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 11C7 10.4477 7.44772 10 8 10L16 10C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12L8 12C7.44772 12 7 11.5523 7 11Z"
            ></path>
            <path
              className="fill-purple-600"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 15C7 14.4477 7.44772 14 8 14L12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16L8 16C7.44772 16 7 15.5523 7 15Z"
            ></path>
          </svg>
        ),
        current: false,
      },
      {
        name: "From Audio",
        href: "/generate-quiz/audio/",
        icon: (
          <svg
            fill="#000000"
            viewBox="0 0 1920 1920"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-5 fill-purple-700 dark:fill-white"
          >
            <path
              d="M1129.432 113v1694.148H903.545l-451.772-451.773V564.773L903.545 113h225.887Zm542.545 248.057C1832.017 521.097 1920 733.882 1920 960.107c0 226.226-87.983 438.898-248.023 598.938l-79.851-79.85c138.694-138.582 214.93-323.018 214.93-519.087 0-196.183-76.236-380.506-214.93-519.2ZM338.83 564.773v790.602H169.415C75.672 1355.375 0 1279.703 0 1185.96V734.187c0-93.742 75.672-169.414 169.415-169.414H338.83Zm1093.922 36.085c95.776 97.018 148.407 224.644 148.407 359.16 0 134.628-52.631 262.253-148.407 359.272l-80.303-79.174c74.656-75.897 115.767-175.4 115.767-280.099 0-104.585-41.111-204.088-115.767-279.986Z"
              fillRule="evenodd"
            />
          </svg>
        ),
        current: false,
      },

    ],
  },
  {
    name: "Host Quiz",
    // href: "/host-quiz",
    icon: (
      // Lock Icon
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        className="h-6 w-6 mr-5 fill-purple-700 dark:fill-white"
      >
        <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z"></path>
      </svg>
    ),
    current: false,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className="h-6 w-6 mr-5 fill-purple-700 dark:fill-white"
        viewBox="0 0 50 50"
      >
        <path d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"></path>
      </svg>
    ),
    current: false,
    bottom: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const userNavigation = [
    { name: "Your Profile", onClick: () => navigate("/settings") },
    { name: "Sign out", onClick: () => handleSignOut() },
  ];

  const [selected, setSelected] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(true);


  const navigate = useNavigate();
  // Function to handle logout
  function handleSignOut() {
    auth
      .signOut()
      .then(() => {
        // Logout successful, you can redirect or handle other logic here
        console.log("Logout successful");
        navigate("/signin");
      })
      .catch((error) => {
        // Handle logout error
        console.error("Logout error:", error);
      });
  }

  const fetchProfileImage = () => {
    const user = auth.currentUser;
    if (user) {
      const photoURL = user.photoURL;
      // Handle photoURL (profile image URL)
      return photoURL;
    } else {
      // User not authenticated or photoURL not available
      return "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
    }
  };
  const fetchName = () => {
    const user = auth.currentUser;
    if (user) {
      const name = user.displayName;
      // Handle name
      return name;
    } else {
      // User not authenticated or name not available
      return "User";
    }
  };
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? storedTheme : "light";
  });
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    const name = fetchName();
    if (name) setSelected(name);
    else setSelected("User");
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden "
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full  pt-5 pb-4 flex-1 flex flex-col bg-light-body">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <MdClose className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
       
                <div className="mt-5 flex-1 h-0 overflow-y-auto ">
                  <nav className="px-2 space-y-1 ">
                    {navigation.map((item) => (
                      
                      <div key={item.name}>
                    <div
                      className={classNames(
                        "flex items-center rounded-xl dark:text-white dark:hover:text-dark-body"
                      )}
                    >
                        <div className="relative dark:hover:text-dark-body">
                          {/* Ensure this parent div is relatively positioned */}
                          {!item.bottom ? (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                "group rounded-md py-2 px-2 flex items-center text-base font-medium dark:text-white dark:hover:text-dark-body"
                              )}
                            >
                              {item.icon}
                              {item.name}
                            </a>
                          ) : (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                "group rounded-md py-2 px-2 flex items-center text-base font-medium dark:text-white dark:hover:text-dark-body"
                              )}
                            >
                              {item.icon}
                              {item.name}
                            </a>
                          )}
                        </div>
                        {item.subMenu && (
                        <FaAngleDown
                          className={`${
                            subMenuOpen && "rotate-180"
                          } duration-300 justify-end ml-auto text-gray-400 group-hover:text-gray-500 mr-4 cursor-pointer`}
                          onClick={() => {
                            setSubMenuOpen(!subMenuOpen);
                          }}
                        />
                      )}
                      </div>
                        {item.subMenu &&
                          subMenuOpen &&
                          item.subMenuItems.map((subItem) => (
                            <a
                              key={subItem.name}
                              href={subItem.href}
                              className={classNames(
                                "group rounded-md py-2 ml-5 px-2 flex items-center text-base font-medium dark:text-white dark:hover:text-dark-body"
                              )}
                            >
                              {subItem.icon}
                              {subItem.name}
                            </a>
                          ))}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-56 md:flex-col md:fixed md:inset-y-0 bg-light-body dark:bg-dark-body  ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="border-r border-gray-200 pt-5 flex flex-col flex-grow overflow-y-auto bg-light-sidebar dark:bg-dark-sidebar rounded-r-3xl">
            <a href="/">
              <div className="flex-shrink-0 px-4 flex items-center">
                <svg
                  className="w-10 h-10 justify-center text-center fill-primary dark:fill-white"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  viewBox="16.14999771118164 15.737491607666016 724.5 403.8750305175781"
                >
                  <path d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"></path>
                </svg>
                <p className="text-2xl pl-5 font-extrabold font-[Libre-Baskerville] text-primary dark:text-white ">
                  Quizeon
                </p>
              </div>
            </a>

            <div className="flex-grow mt-5 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1 ">
                {navigation.map((item) => (
                  <div key={item.name}>
                    <div
                      className={classNames(
                        "flex items-center rounded-xl dark:text-white dark:hover:text-dark-body"
                      )}

                    >
                      <div className="relative">
                        {" "}
                        {/* Ensure this parent div is relatively positioned */}
                        {!item.bottom ? (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              "group rounded-md py-2 px-2 flex items-center text-base font-medium dark:text-white dark:hover:text-dark-body"
                            )}

                          >
                            {item.icon}
                            {item.name}
                          </a>
                        ) : (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              "group rounded-md py-2 px-2 flex items-center text-base font-medium bottom-0 w-full dark:text-white dark:hover:text-dark-body"
                            )}

                          >
                            {item.icon}
                            {item.name}
                          </a>
                        )}
                      </div>

                      {item.subMenu && (
                        <FaAngleDown
                          className={`${
                            subMenuOpen && "rotate-180"
                          } duration-300 justify-end ml-auto text-gray-400 group-hover:text-gray-500 mr-4 cursor-pointer`}
                          onClick={() => {
                            setSubMenuOpen(!subMenuOpen);
                          }}
                        />
                      )}
                    </div>
                    {item.subMenu &&
                      subMenuOpen &&
                      item.subMenuItems.map((subItem) => (
                        <a
                          key={subItem.name}
                          href={subItem.href}
                          className={classNames(
                            "group rounded-md py-2 ml-5 px-2 flex items-center text-base font-medium dark:text-white dark:hover:text-dark-body"
                          )}
                        >
                          {subItem.icon}
                          {subItem.name}
                        </a>
                      ))}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="md:pl-56  ">
          <div className="max-w-screen flex flex-col ">
            <div className="sticky flex flex-shrink-0 h-16 border-b border-gray-200">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <GiHamburgerMenu className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-end  dark:bg-dark-body bg-light-body  ">
                <div className="flex-1 relative flex justify-start pl-10 text-black  items-center">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <div className="mt-1 relative">
                          <Listbox.Button className="relative w-full  border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <div className="flex items-center">
                              <BsFillPeopleFill
                                className="h-5 w-5 dark:text-gray-100"
                                aria-hidden="true"
                              />
                              <span className="ml-3 block truncate dark:text-white">
                                {selected}'s Space
                              </span>
                            </div>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                              <MdArrowDropDown
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full  shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                             
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <div className="pt-3 pr-10">
                  <div
                    className="transition duration-500 ease-in-out  text-gray-800 font-bold py-2 px-4 rounded"
                    onClick={toggleTheme}
                  >
                    {theme === "light" ? (
                      <IoSunny className="w-6 h-6  hover:text-yellow-500 hover:rotate-180 hover:duration-500 duration-500" />
                    ) : (
                      <IoMoon className="w-6 h-6 text-white hover:text-black -rotate-90 hover:rotate-45 hover:duration-500 duration-500" />
                    )}
                  </div>
                </div>
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div className="relative">
                      <Menu.Button className="max-w-xs flex mr-10 items-start top-0 right-0 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={fetchProfileImage()}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg dark:bg-dark-onbackground bg-slate-500 text-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-slate-500 cursor-pointer" : "",
                                  "block py-2 px-4 text-sm text-gray-700",
                                  "text-white"
                                )}
                                onClick={item.onClick}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <main className="min-h-screen dark:bg-dark-body bg-light-body">
              <div className="">
                
                <div className=" ">
                  {/* Replace with your content */}
                  <Outlet />
                  {/* /End replace */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
