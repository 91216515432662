export default function Popup(props) {
  return (
    <div class="fixed z-10 inset-0 flex items-center justify-center overflow-y-auto">
      <div class="bg-white rounded-lg shadow dark:bg-gray-700 ">
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Question {props.res.question.indexOf(props.res.question) + 1}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => {
              props.setShowModal(!props.showModal);
            }}
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="p-3 md:p-5">
          <div
            class="text-lg lg:text-base radio font-semibold outline-none"
            contentEditable={true}
          >
            {props.res.question}
          </div>
          <div>
            {props.res.options != null ? (
              <>
                {props.res.options.map((option, optionIndex) => (
                  <div
                    className={`flex bg-gray-100 text-gray-700 rounded-md px-3 py-2 my-3 hover:bg-indigo-300 cursor-pointer ${
                      props.res.answer === option ? "bg-green-200" : ""
                    }`}
                    key={optionIndex}
                  >
                    <input type="radio" className="" />
                    <div
                      contentEditable={true}
                      className={`pl-2  flex outline-none `}
                    >
                      {option}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div
                  className={`flex bg-gray-100 text-gray-700 rounded-md px-3 py-2 my-3 hover:bg-indigo-300 cursor-pointer`}
                >
                  <div
                    contentEditable={true}
                    className={`pl-2  flex outline-none `}
                  >
                    {props.res.answer}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div class="flex justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            type="button"
            class="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Update Question
          </button>
        </div>
      </div>
    </div>
  );
}
