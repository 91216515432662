import { React } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import Practice from "./Components/Practice_Quiz/Practice";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
    // <Practice />

);
