import React from "react";

const Welcome = ({ onStartQuiz, quizCode, setQuizCode, handleInputChange }) => {

 

  const handleStartClick = () => {
    onStartQuiz(quizCode);
  };

  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="p-6  rounded max-w-sm w-full text-center">
        <h1 className="text-2xl font-bold mb-4">Let's Start Practicing</h1>
        <p className="mb-4">Enter your quiz code to get started:</p>
        <input
          type="text"
          value={quizCode}
          onChange={handleInputChange}
          className="mb-4 px-4 py-2 border rounded w-full dark:bg-dark-buttons"
          placeholder="Enter Quiz Code"
        />
        <button
          onClick={handleStartClick}
          className="px-4 py-2 bg-blue-500 text-white rounded w-full hover:bg-blue-600"
        >
          Start Quiz
        </button>
      </div>
    </div>
  );
};

export default Welcome;
