import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import Login from './Components/Authentication/Login';
import Signup from './Components/Authentication/Signup';
import Quest from './Components/Create Quiz/Quest';
import Settings from './Components/Settings';
// import Join from './Components/Join Quiz/Join';
import Home from './Components/Home/Home';
import Error from './Components/Authentication/Error';
import Dashboard from './Components/Dashboard/Dashboard';
// import Quiz from './Components/Create Quiz/Create_Quiz';
import Host_quiz from './Components/Host Quiz/Host_quiz';
import Host from './Components/Host Quiz/Host';
// import Join_home from './Components/Join Quiz/Join_home';
import Results from './Components/Join Quiz/Result';
import Practice from './Components/Practice Quiz/Practice';

import Host_quest from './Components/Host Quiz/Host_quest';
import Gen_Audio from './Components/Create Quiz/Audio/Gen_Audio';
import Dummy from './Components/Authentication/Dummy';
import Forgot from './Components/Authentication/Forgot';
import Choose_Quiz from './Components/Create Quiz/Choose_Quiz';
import { auth } from './backend/config';
import './App.css';
import { onAuthStateChanged } from 'firebase/auth';
import Recovery from './Components/Authentication/Recovery';
import { SpeedInsights } from "@vercel/speed-insights/react"
export default function App() {
  const [authToken, setAuthToken] = useState(false); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthToken(true);
      } else {
        setAuthToken(false);
      }
      setLoading(false);    
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center bg-transparent bg-opacity-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          width="200"
          height="200"
          style={{ shapeRendering: 'auto' }}
        >
          <g>
            <path
              fill="none"
              stroke="#26a612"
              strokeWidth="8"
              strokeDasharray="42.76482137044271 42.76482137044271"
              d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
              strokeLinecap="round"
              style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
            >
              <animate
                attributeName="stroke-dashoffset"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;1"
                values="0;256.58892822265625"
              ></animate>
            </path>
          </g>
        </svg>
      </div>
    );
  }

  return (
    <>
      <Router>
        <Routes>
          <Route index path="/signin" element={<Login setAuthToken={setAuthToken} />} />
          <Route path="/signup" element={<Signup  setAuthToken={setAuthToken} />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset/:token" element={<Recovery />} />
          <Route path="/" element={<Home />} />
          <Route element={<Sidebar />}>
            <Route path="/dashboard" element={authToken ? <Dashboard /> : <Dummy />} />
            <Route path="/host-quiz" element={authToken ? <Host_quiz /> : <Dummy />} />
            <Route path="/settings" element={authToken ? <Settings /> : <Dummy />} />
            <Route path="/generate-quiz/content" element={authToken ? <Quest /> : <Dummy />} />
            <Route path="/quiz/" element={authToken ? <Choose_Quiz /> : <Dummy />} />
            <Route path="/generate-quiz/audio" element={authToken ? <Gen_Audio /> : <Dummy />} />
            <Route path="/host" element={authToken ? <Host /> : <Dummy />} />
            <Route path="/host/:id" element={authToken ? <Host_quest /> : <Dummy />} />
          </Route>
          {/* <Route path="/join-quiz/" element={<Join_home />} /> */}
          {/* <Route path="/quiz/:id" element={<Join />} /> */}
          <Route path="/practice" element={<Practice />} />
          <Route path="/quiz-results/:id" element={<Results />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
          <SpeedInsights />
          </>
  );
}
