import Swal from "sweetalert2";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../backend/config";



export default function Forgot() {
  const [email, setEmail] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire("Success", "Password reset email sent.", "success");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      Swal.fire("Error", "Failed to send password reset email.", "error");
    }
  };
  return (
    <div>
      <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex mb-4">
            <svg
              viewBox="575"
              className="w-16 h-16 justify-center text-center fill-[#7a329d]"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              viewBox="16.14999771118164 15.737491607666016 724.5 403.8750305175781"
            >
              <path d="M669.4,347.5L638.9,378c-55.3,55.3-145.1,55.3-200.4,0L172.9,112.4c0,0,0,0,0,0c0,0,0,0,0,0C149.7,89.2,117.2,81,87.3,87.8   l30.5-30.5c55.3-55.3,145.1-55.3,200.4,0l265.6,265.6c0,0,0,0,0,0C607,346.1,639.6,354.3,669.4,347.5L669.4,347.5z M612.9,108.4   c-0.6,0.3-1.3,0.7-1.9,1.1c-0.3,0.2-0.7,0.4-1,0.6c20.9,30.7,19.3,72-4.6,101.1l-56.6,56.6l45.1,45.1c28.7,28.7,74.4,30.3,105,4.8   c1.1-0.9,2.2-1.9,3.2-2.9c51.4-54.5,51.4-139.7,0-194.3C677.4,97.6,641.5,93.5,612.9,108.4L612.9,108.4z M438.5,57.3l-50.1,50.1   l100.2,100.2l50.1-50.1l45.1-45.1c5.3-5.3,11-9.8,17.1-13.5c0.1,0,0.1-0.1,0.2-0.1c20.7-12.6,45.3-16.3,68.3-11.1l-30.5-30.5   C583.6,1.9,493.8,1.9,438.5,57.3L438.5,57.3z M57.9,117.7c-1.1,0.9-2.2,1.9-3.2,2.9C3.3,175,3.3,260.2,54.7,314.8   c24.6,22.9,60.6,27,89.2,12.1c0.6-0.3,1.3-0.7,1.9-1.1c0.3-0.2,0.7-0.4,1-0.6c-20.9-30.7-19.3-72,4.6-101.1l56.6-56.6l-45.1-45.1   C134.2,93.7,88.5,92.1,57.9,117.7z M218,277.8l-45.1,45.1c-5.3,5.3-11,9.8-17.1,13.5c-0.1,0-0.1,0.1-0.2,0.1   c-20.7,12.6-45.3,16.3-68.3,11.1l30.5,30.5c55.4,55.4,145.1,55.3,200.4,0l50.1-50.1L268.1,227.7L218,277.8L218,277.8z"></path>
            </svg>
            <p className="text-4xl ml-3 mt-2 font-extrabold  font-[Libre-Baskerville] text-[#7a329d]">
              Quizeon
            </p>
          </div>

          <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h1 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot your password?
            </h1>
            <p class="font-light text-gray-500 dark:text-gray-400">
              Don't fret! Just type in your email and we will send you a code to
              reset your password!
            </p>
            <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handlePasswordReset} action="#">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
              </div>
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required=""
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="terms"
                    class="font-light text-gray-500 dark:text-gray-300"
                  >
                    I accept the{" "}
                    <a
                      class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                      href="#"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                class="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Reset password
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
