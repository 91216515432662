import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaRegCopy } from "react-icons/fa";

import { Link } from "react-router-dom";
import { SHA256 } from "crypto-js";
// Components Import
import QuestionType from "./QuestionType";
import ExportData from "./Export_Data";
import MCQType from "../Question Type/MCQ_type";
import TimerCountDown from "./Timer";
import ShortAns from "../Question Type/Short_ans";
import TFType from "../Question Type/TF_type";
import FIB from "../Question Type/FIB";
import Multi from "../Question Type/Multi-Correct";
import { auth } from "../../backend/config";
import { serverUrl } from "../../backend/config";
// Icons Import
import { FiAlertTriangle } from "react-icons/fi";
import { FaCopy, FaQuestion } from "react-icons/fa6";
import Modal from "react-modal";

export default function Quest() {
  const [text, setText] = useState("");
  const [quizName, setQuizName] = useState(""); // State for quiz name
  const [result_val, setResultVal] = useState([]);
  const wordLimit = 1000;
  const [genLoading, setGenLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [genContent, setGenContent] = useState(null);
  const [genError, setGenError] = useState(false);
  const [questionType, setQuestionType] = useState("MCQ");
  const [difficultyLevel, setDifficultyLevel] = useState("Medium");
  const [numberOfQuestions, setNumberOfQuestions] = useState("5");
  const [numberOfOptions, setNumberOfOptions] = useState("3");
  const setEdit = useState(false);
  const navigate = useNavigate();
  let parsedData;
  const [genQuestionType, setGenQuestionType] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isAnimating, setIsAnimating] = useState(false);
  const texts = ["First text", "Second text", "Third text", "Fourth text"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uniqueCode, setUniqueCode] = useState(null);
  const [copied, setCopied] = useState(false);

  const [uniqueRenderKey, setUniqueRenderKey] = useState(0);


  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(uniqueCode);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };
  useEffect(() => {
    if (isAnimating) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + 1 >= texts.length) {
            clearInterval(interval);
            setIsAnimating(false);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 2000); // Change text every 2 seconds

      return () => clearInterval(interval);
    }

  }, [isAnimating, texts.length]);


  const handleGenerate = async () => {
    setGenLoading(true);
    setGenError(false);
    setCurrentIndex(0);
    setIsAnimating(true);
    const uid = auth.currentUser.uid;

    console.log("server",serverUrl);

    try {
      // Simultaneously call AWS service to generate quiz data
      const remaining = wordLimit - text.trim().split(/\s+/).length;
      console.log("Remaining words:", remaining);
      if (remaining < 0) {
        throw new Error(
          "Word limit exceeded. Please reduce the number of words. or Upgrade your Plan"
        );
      }
      if (text.trim().split(/\s+/).length < 10) {
        throw new Error("Minimum 10 words required to generate questions");
      }
      const awsResponse = await axios.post(
        // "https://flask-service.6jkv49phhb0j2.us-east-1.cs.amazonlightsail.com/item",
        `${serverUrl}/generate`,
        {
          option: questionType,
          no_of_quest: numberOfQuestions,
          no_of_opt: numberOfOptions,
          diff_level: difficultyLevel,
          text: text,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let awsData = await awsResponse.data;
      console.log("aws data" + awsData);
      
      let awsDataArray;

      try {
        awsDataArray = JSON.parse(awsData);
      } catch (error) {
        console.log(
          "Direct JSON parse failed, attempting manual formatting..."
        );

        // Replace any potential newlines or unwanted characters
        let formattedData = awsResponse.data
          .replace(/}\s*\n\s*{/g, "},{")
          .trim();

        // Ensure the formatted data is wrapped in square brackets
        formattedData = `[${formattedData}]`;

        // Debug: Log formatted data before parsing
        console.log("Formatted Data:", formattedData);

        // Attempt to parse the manually formatted data
        try {
          awsDataArray = JSON.parse(formattedData);
        } catch (parseError) {
          console.error(
            "Manual formatting JSON parse error:",
            parseError,
            "for data:",
            formattedData
          );
          throw parseError;
        }
      }

      parsedData = awsDataArray;

      // Update the unique render key to force rerender
      setUniqueRenderKey(prevKey => prevKey + 1);

      setGenContent(null);
      setGenContent(true);
      setResultVal(parsedData);
      setGenQuestionType(questionType);

      if (quizName === "") {
        setQuizName("Untitled Quiz");
      }
      console.log("Unique Code:", uniqueCode);
      console.log(parsedData);

      // Only post to localhost after successful AWS generation
      const response = await axios.post(
        `${serverUrl}/api/quiz/${uid}`,
        {
          quiz_id: uniqueCode,
          quiz_name: quizName,
          quiz_prompt: text,
          difficulty_level: difficultyLevel,
          created_at: new Date().toISOString(),
          questionType: questionType,
          generateType: "text",
          audioUrl: "",
          questions: parsedData,
        }
      );

      console.log(response.data);

    } catch (error) {
      console.error("Error:", error);
      setGenError(true);
      setErrMessage(error.message);
    }

    setGenLoading(false);
  };

  useEffect(() => {
    setUniqueCode(generateKey());
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const generateKey = () => {
    const timestamp = Date.now().toString();
    const hash = SHA256(timestamp).toString();
    return hash.slice(0, 6).toUpperCase(); // Return first 6 characters of the hash
  };

  const remainingWords = text.trim().split(/\s+/).length;
  const isDisabled = remainingWords < 0;

  const updateQuizOnBackend = async () => {
    const uid = auth.currentUser.uid;
    console.log("Quiz ID:", uniqueCode);

    try {
      const response = await axios.put(
        `/api/quiz/${uid}/${uniqueCode}`,
        {
          quizId: uniqueCode,
          questionType: questionType,
          updatedQuiz: {
            quiz_name: quizName,
            quiz_prompt: text,
            difficulty_level: difficultyLevel,
            created_at: new Date().toISOString(),
            questions: result_val,
          },
        }
      );
      console.log("Quiz updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating quiz:", error);
    }
  };

  const handleSave = () => {
    // updateQuizOnBackend();
    // setIsSaved(!isSaved);
    if (isSaved) {
      updateQuizOnBackend();
    }
    setIsSaved(!isSaved);
  };
  

  useEffect(() => {
    if (remainingWords < 0) {
      setText(text.slice(0, wordLimit));
    } else {
      // const remaining = wordLimit - text.trim().split(/\s+/).length;
      // setText(text.slice(0, remaining));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    console.log("genContent changed:", genContent);
    console.log("result_val updated:", result_val);
    setGenContent(true);
    setResultVal(result_val);
  }, [genContent, result_val]);


  return (
    <>
      <section className="text-gray-600 w-full">
        <div className="container">
          <div className="flex justify-evenly items-center pt-8 pb-8">
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div className="flex justify-between items-center">
                <Link to="/quiz">
                  <button className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                    Back
                  </button>
                </Link>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">Title: </span>
                  </div>
                  <input
                    type="text"
                    name="quiz-title"
                    id="quiz-title"
                    className="focus:ring-indigo-500 focus:border-indigo-500 dark:bg-white/10 block w-full pl-16 sm:pl-14 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Untitled Quiz"
                    autoComplete="off"
                    value={quizName}
                    onChange={(e) => setQuizName(e.target.value)} // Update quizName state
                  />
                </div>
              </div>
            </div>
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8 flex justify-evenly">
              <div className="justify-center">
                <button className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  onClick={() => setIsModalOpen(true)}
                >
                  Practice
                </button>
              </div>
              <div className="justify-center">
                <ExportData response={result_val} />
              </div>
              {!genError && genContent && (
                <div className="justify-center">
                  <button
                    className="px-4 py-2 bg-[#6247AA] text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    onClick={handleSave}
                  >
                    {!isSaved ? "Edit" : "Save"}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div className="w-full float block mb-5">
                <QuestionType
                  questionType={questionType}
                  setQuestionType={setQuestionType}
                  difficultyLevel={difficultyLevel}
                  setDifficultyLevel={setDifficultyLevel}
                  numberOfQuestions={numberOfQuestions}
                  setNumberOfQuestions={setNumberOfQuestions}
                  numberOfOptions={numberOfOptions}
                  setNumberOfOptions={setNumberOfOptions}
                />
              </div>
              <div className="flex items-start space-x-2">
                <div className="min-w-0 flex-1 ">
                  <div className="relative ">
                    <div className="border border-gray-300 dark:bg-white/10 dark:text-white bg-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                      <label htmlFor="comment" className="sr-only">
                        Type Here
                      </label>
                      <textarea
                        rows={10}
                        name="comment"
                        id="comment"
                        className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm dark:bg-white/10"
                        placeholder="Type here..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />

                      <div className="py-2" aria-hidden="true">
                        <div className="py-px">
                          <div className="h-9" />
                        </div>
                      </div>
                    </div>
                    <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between dark:bg-white/10">
                      <div className="flex items-center space-x-5">
                        <div className="flex items-center">
                          <span
                            className={`text-sm ${
                              remainingWords > wordLimit
                                ? "text-red-600"
                                : "text-gray-500"
                            }`}
                          >
                            Words left: {wordLimit - remainingWords}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center">
                        {genLoading && (
                          <div>
                            <TimerCountDown />
                          </div>
                        )}
                        {genLoading && (
                          <div className="w-6 mx-3 h-6 border-4 border-gray-300 border-t-4 border-t-gray-800 rounded-full animate-spin"></div>
                        )}

                        <button
                          className="inline-flex items-center px-4 py-2 disabled:bg-slate-500 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          disabled={!isDisabled && genLoading}
                          onClick={handleGenerate}
                        >
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:w-1/2 lg:w-1/2 md:w-full px-8">
              <div 
              key={uniqueRenderKey}
              className="overflow-y-auto overflow-x-auto h-[36rem]">
                {!genError ? (
                  <>
                    {genContent === null && (
                      <div className="flex justify-center items-center h-full">
                        <FaQuestion className="h-20 w-20 text-gray-300 animate-fade-right animate-thrice animate-ease-in" />
                        Questions will be displayed here
                        {currentIndex >= 0 && (
                          <p
                            key={currentIndex}
                            className="opacity-0 animate-fadeRight"
                          >
                            {texts[currentIndex]}
                          </p>
                        )}
                      </div>
                    )}
                    {genContent === false && (
                      <div className="flex justify-center items-center h-full">
                        <span className="loadanim"></span>
                      </div>
                    )}
                    {genContent && (
                      <>
                        {genQuestionType === "MCQ" && (
                          <MCQType
                            mcq_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "True or False" && (
                          <TFType
                            tf_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Short Answer" && (
                          <ShortAns
                            short_ans={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Fill in the Blanks" && (
                          <FIB
                            fib_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                        {genQuestionType === "Multi-correct" && (
                          <Multi
                            mc_response={result_val}
                            edit={isSaved}
                            setEdit={setEdit}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <FiAlertTriangle className="h-20 w-20 text-red-500 mb-3" />
                    <span className="text-red-500">
                      {errMessage ? errMessage : "An error occurred"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (<Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Unique Code Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'static',
            inset: 'unset',
            width: '90%',
            maxWidth: '500px',
            margin: '0 auto',
            padding: '0',
            border: 'none',
            background: 'none',
            overflow: 'unset',
          },
        }}
      >
        <div className="p-4 bg-white rounded shadow-lg max-w-lg mx-auto ">
          <div className="flex flex-row border-b-2 justify-between mb-4">
            <div className="text-xl font-bold dark:text-black">Share</div>
            <div> <button
          type="button"
          className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
          onClick={closeModal}
          aria-label="Close"
        >
          <span className="[&>svg]:h-6 [&>svg]:w-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </button></div>
          </div>
       
          {/* <h2 className="text-xl font-bold mb-4 dark:text-black">Share</h2> */}
          <p className="dark:text-black pb-8">Share this code with you friends, So that they can also practice the questions created by you</p>

          <div className="mb-4 p-2 bg-gray-100 border w-fit border-gray-300 rounded flex justify-center text-center">
              <span className="text-lg font-bold dark:text-black">{uniqueCode}</span>
              
            {copied? <FaCopy className="ml-2 mt-1 cursor-pointer"/> : <FaRegCopy className="text-gray-500 ml-2 mt-1 cursor-pointer" onClick={handleCopy} size="20" />}
          </div>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => navigate(`/practice`)}
          >
            Practice
          </button>
        </div>
      </Modal>)}
    </>
  );
}
